import React from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const FAQ = () => {
  return (
    <div className="faq" style={{padding: '20px'}}>
      <Collapse>
        <Panel header="STEMsocial in a nutshell" key="1" style={{textAlign: 'left'}}>
            <p>STEMsocial initially aimed at improving the quality of STEM (science, technology, engineering & mathematics) content using blockchain technology. As the project developed, our objectives were continually and more ambitiously redesigned. STEMsocial now intends to increase the usage of the Hive blockchain for science communication, developing an innovative way for those with the greatest knowledge of STEM subjects (i.e. researchers actively involved in groundbreaking research) to communicate about their work to mainstream audiences (and potentially earn monetary compensation via Hive tokens) without having to leave the majority of scientific reporting to journalists. In this way, the STEMsocial platform can provide a solution to the problem of which only the most positive, groundbreaking and bizarre stories get heard and are often misinterpreted.</p>

            <p>In other words, we are working to create an environment where STEM professionals and enthusiasts alike are incentivised to take STEM communication in a new direction; directly to the readers without the need of a third party.</p>
 
            <p>The STEMsocial community consists today of scientists and STEM experts, science bloggers, curators, collaborators and more, from all over the world. We all share ideas, work together or simply hang out in our Discord server. We also share our actions (through announcements on the STEMsocial blog on Hive), where anyone can, if motivated, play an important part in contributing by raising their voices.</p>
        </Panel>
        <Panel header="How do I get supported?" key="2" style={{textAlign: 'left'}}>
            <p>Posts are supported according to objective and subjective criteria.</p>
 
            <p>Our objective requirements ensure that a post is related to STEM (Science, Technology, Engineering, Mathematics) content, although we do allow for some flexibility in what we consider as STEM. For example, history, psychology, economics, medicine and more are supported.</p>
  
            <p>We always make sure to verify that a post is not fraudulent or dishonest. This includes a check of the references and image credits (that must also be available for commercial purposes), as well as a plagiarism verification. A supported post must indeed be one's own work.</p>
  
            <p>There is no length requirement, we believe in quality over quantity.</p>
  
            <p>Finally, we work on the principles of social media, so content creators are encouraged to utilize enthusiasm, humour, natural tone and simplified language to best reach larger audiences. We also stress that STEMsocial accepts content in multiple languages: Chinese, English, French, German and Spanish.</p>
  
            <p>Our subjective criteria assess the post quality (including originality, style, effort, creativity, etc.) and correspondingly, the strength of the STEMsocial support (subject to change for better distribution when necessary).</p>
            <ul>
                <li>10%: This voting strength is used either to encourage a new user or to acknowledge effort, often complemented with relevant guidance.</li>
                <li>30%: An average vote for an average post that does not report anything new or exciting, but generally fulfils the objective standards we have set out.</li>
                <li>65%: These are very good posts that show both character in their writing style as well as bring unique or fascinating content to the blockchain, including users’ attention. We feel content of this caliber contains information that users might search for on the greater web, or it may be simply apparent that a lot of effort went into creating the post.</li>
                <li>100%: This is the best of the best, often written by trusted and long-term loyal members of the community who have a history of flare and unique approaches to their subject. This content may be completely original, even groundbreaking, demonstrating one's own research, and it will typically be an easy and even fun read. We expect this content to play role in bringing users into our community purely on its impressive nature alone!</li>
            </ul>

            <p>Articles posted from the STEMsocial app get more strongly supported, as well as those sharing a fraction of their rewards with the @stemsocial account (to be set as a beneficiary). In total, both these criteria can bring up to 10% stronger vote support.</p>
  
            <p>We emphasize that we look for content that is easily digestible to non-STEM degree holding readers. We of course do not discount particularly technical work, but one needs to consider why we publish on a social media blockchain to begin with, and how we can best utilise it for mainstream adoption. We also discourage posts that are simple rewordings of other articles such as those on Wikipedia.</p>
  
            <p>If a given publication is above our minimum support, it must always be double-checked by a second curator who may further verify the above details before confirming the upvote. Moreover, our honor members are always on the case, making sure the curation team is not being lax in their job, finding problems missed, and more. If an illegitimate author manages to pass through this screening, the public can also view our curation history and highlight any mistakes we may have made in our complain channel on our Discord server, which, if reasonable evidence is provided, will cause rewards to be removed and for that author to be blacklisted from future curation.</p> 
        </Panel>
        <Panel header="About Curation" key="3" style={{textAlign: 'left'}}>
            <p>The STEMsocial curators primarily scour the 'STEMsocial' category on Hive (i.e. the STEMsocial community in Hive language) to find the best STEM content posted on chain. Whilst we also browse other STEM tags outside the community ('science,' 'biology,' 'chemistry', 'engineering', etc.) to discover new content creators, the non-mandatory usage of our community makes our life easier and increases the chances for your post to be found.</p>
 
            <p>As there is no post promotion channel on our Discord server, posting through the community is important for a post to be noticed by the team, especially for new users. This is automatically enforced when our app is used.</p>
  
            <p>If you have not been selected for STEMsocial support, your post either fails to meet our standards or it has simply been missed. Curators are (unfortunately) all human, so that this happens from time to time. We suggest you just keep writing, for passion and not for money. If your posts are good, you will eventually be noticed.</p> 
        </Panel>
        <Panel header="Being a member of the STEMsocial community" key="4" style={{textAlign: 'left'}}>
            <p>Though no 'membership' is required, by being an active part of our growing community, you get to both enjoy an environment of like-minded individuals as well as contribute your own value in return via posts, discussions, comments and other forms of engagement.</p>
        </Panel>
        <Panel header="The STEMsocial team" key="5" style={{textAlign: 'left'}}>
            <p>Management: <a href={`/p?user=lemouth`}>@lemouth</a>, <a href={`/p?user=mobbs`}>@mobbs</a></p>
 
            <p>Curators: <a href={`/p?user=carloserp-2000`}>@carloserp-2000</a>, <a href={`/p?user=chappertron`}>@chappertron</a>, <a href={`/p?user=corsica`}>@corsica</a>, <a href={`/p?user=gentleshaid`}>@gentleshaid</a>, <a href={`/p?user=iamphysical`}>@iamphysical</a>, <a href={`/p?user=mathowl`}>@mathowl</a>, <a href={`/p?user=robertyan`}>@robertyan</a>, <a href={`/p?user=sco`}>@sco</a>, <a href={`/p?user=softmetal`}>@softmetal</a></p>
 
        </Panel>
        {/* Add more questions and answers as needed */}
      </Collapse>
    </div>
  );
};

export default FAQ;
