import React from 'react';
import { Row, Col, Typography } from 'antd';
import './tos.css'

const { Title, Paragraph } = Typography;

const TermsOfService = () => {
  // Define your terms of service as an array of objects
  const terms = [
    {
      title: 'Lorem ipsum dolor sit amet',
      content: 'The STEMsocial app does not retain any personal information; all private keys and passwords will work through either the HiveSigner or Hive keychain service which users must agree to use in order to have full access to our services.',
    },
    {
      title: 'Vestibulum tincidunt ex vitae',
      content: 'STEMsocial is not responsible for content that may appear on this website. All content is derived from the Hive blockchain. STEMsocial does provide a blacklisting service which works hard to prevent any fraudulent or abusive content from appearing on the app, but the nature of the blockchain neccessarily permits an unrestricted, open-access environment. Our app, however, limits its content to a small area of the Hive blockchain, primarily drawing content from the STEM (Science, Technology, Engineering & Mathematics)-related tags and sub-communities.',
    },
    {
      title: 'Nullam at turpis nec elit facilisis',
      content: 'STEMsocial does not own any content appearing on this website, with the exception of content from the user account @steemstem. All other content solely belongs to the original author, and it is thus their responsibility to decide and control whether their content is displayed on the STEMsocial app.',
    },
    {
      title: 'Nullam at turpis nec elit facilisis',
      content: 'All content appearing on the app is completely visible to the public and accessible on search engines accordingly.',
    },
    {
        title: 'Nullam at turpis nec elit facilisis',
        content: 'The app draws its content from the Hive blockchain, and therefore all components provided are to be offered for informational purposes only. The website is not responsible for the accuracy or reliability of such content.',
      }
    // Add more terms here
  ];

  return (
    <div className="terms-of-service">
      <Title level={2}>Terms of Service</Title>
      <Title level={5}>By accessing and using this website, you accept the terms and conditions laid out below, and will thus be subject to any rules applicable. Should you use this service, you will therefore have agreed on the above. If not, please do not use this website.</Title>
      <Row gutter={[16, 16]}>
        {terms.map((term, index) => (
          <Col key={index} span={24}>
            <ul>
              <li style={{textAlign: 'left'}}>{term.content}</li>
            </ul>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TermsOfService;
