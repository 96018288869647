import {React, useCallback, useState, useMemo, useEffect} from "react";
import {keychain, isKeychainInstalled, hasKeychainBeenUsed} from '@hiveio/keychain'
import ops from "../services/hiveOps"
import SimpleMdeReact from "react-simplemde-editor";
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import randomstring from 'randomstring';
import apiOps from "../services/apiOps";
import SelectSearch from 'react-select-search';
import { ToastContainer } from "react-toastr";
import axios from "axios";
import { notification } from 'antd';

// components


const hive = require("@hiveio/hive-js")
function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

//let popularCommunities = [];


//let inscrybmde = new InscrybMDE()


const author = getUrlParameter('user')
let permlink = getUrlParameter('permlink')

function Edit() {
    let [title, setTitle] = useState('')
    let [value, setValue] = useState("");
    let [tags, setTags] =  useState([])
    let [chosenCommunity, setChosenCommunity]= useState('')
    let [communities, setCommunities] = useState([])
    let [lineAndCursor, setLineAndCursor] = useState(null);

    let getLineAndCursorCallback = useCallback((position) => {
        setLineAndCursor(position);
    }, []);

    const autofocusNoSpellcheckerOptions = useMemo(() => {
        return  {
          hideIcons: ['preview', 'side-by-side', 'image'],
          previewImagesInEditor: true,
          promptURLs: true
        };
    }, []);

    function finishPosting(author, permlink) {
        window.location.replace(`/p?user=${author}`)
    }

    useEffect(async () => {
        let content = await hive.api.getContentAsync(author, permlink)
        setChosenCommunity(content.category)

    }, [])

    useEffect(async () => {
        let popularCommunities = await ops.getPopularCommunities()
        setCommunities(popularCommunities);
    }, [])


    useEffect(async () => {
        let content = await hive.api.getContentAsync(author, permlink)
        setTitle(content.title);

    }, [])

    useEffect(async () => {
        let content = await hive.api.getContentAsync(author, permlink)
        setValue(content.body);

    }, [])

    useEffect(async () => {
        let content = await hive.api.getContentAsync(author, permlink)
        const json = JSON.parse(content.json_metadata)
        console.log(json)
        const theTags = json.tags
    }, [])
   
    async function handlePostEdit() {
        notification.open({
            //message: 'Notification Title',
            description:
            'Editing',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
        const content = await hive.api.getContentAsync(author, permlink)
        const title = document.getElementById('postTitle').value
        let body = value;
        const parentAuthor = '';
        const jsonMetadata = content.json_metadata
        const parsedJson = JSON.parse(jsonMetadata)
        const parentPermlink = chosenCommunity.length > 0 ? chosenCommunity : parsedJson.tags[0]

        console.log(jsonMetadata)

        const comment = {
            parent_author: parentAuthor,
            parent_permlink: parentPermlink,
            author,
            permlink,
            title,
            body,
            json_metadata: jsonMetadata
        };

        let benefactor_global = [
            [0, {beneficiaries: [{account: 'stemsocial', weight: 500}]}]
        ];

        const operation = ['comment', comment]

        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', author, 5,  'test memo', 'HIVE')
        
        const keychainStatus = localStorage.getItem('keychain')

        console.log(comment)

        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {
                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', author, [operation], 'Posting');
                if (success) {
                    finishPosting(author, permlink)
                }
                
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = localStorage.getItem('ajbhs')
                    //const sendPost = await hive.broadcast.commentAsync(dybnjg, parentAuthor, parentPermlink, author, permlink, title, body, JSON.stringify(jsonMetadata))
                    await hive.broadcast.sendAsync({
                        operations: [operation]
                      }, {posting: dybnjg});
                    finishPosting(author, permlink)
                } catch (error) {
                    console.log(error)
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Error encountered',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                }
            }
        }
    }

    async function changeSelect(v) {
        setChosenCommunity(v.target.value)
    }

    async function changeTitle(v) {
        setTitle(v.target.value)
    }  

    async function onImageInputChange(event) {
        try {
            let body = new FormData()
            body.set('key', '49f6872819f2b77a024f3a4326f29a56')
            body.append('image', event.target.files[0])

            let imageUploadOp = await axios({
                method: 'post',
                url: 'https://api.imgbb.com/1/upload',
                data: body
            })

            let url = imageUploadOp.data.data.display_url
            let text_to_insert = `![](${url})`
            
            let line = lineAndCursor.line
            
            let valueArr = value.split('\n')

            valueArr.splice(line, 0, text_to_insert)
        
            setValue(valueArr.join('\n'))
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className="post-detail" id="post-page-content" style={{paddingTop: '15vh'}}>
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 col-sm-12 container" key={author} data-author={author}>
                    <div className="container mb-3 text-cont">
                        <label htmlFor="postTitle" className="form-label text-left">Title</label>
                        <input type="text" className="form-control" id="postTitle" placeholder="Enter a Title for Your Post Here" value={title} onChange={changeTitle} />
                    </div>

                    {/*<div className="container mb-3 text-cont"> 
                       <label htmlFor="communitiesSelect">Select one popular community(optional)</label>
                        <select value={chosenCommunity} onChange={changeSelect} style={{
                            width: '100%',
                            height: '50px'
                        }} id="communitiesSelect">
                            {communities.map((option) => (
                                <option value={option.community.name}>{option.community.title}</option>
                            ))}
                        </select>
                    </div>*/}

                    <div className="container mb-3 text-start">
                        <input accept="image/gif,image/jpeg,image/jpg,image/png" type="file" id="upInput" onChange={onImageInputChange} placeholder='Click to choose file' /><br />
                        <small>Click to upload image</small><br />
                    </div>

                    <div className="container mb-3">
                        <label htmlFor="postEditor" className="form-label">Description</label>
                        <SimpleMdeReact value={value} onChange={(value) => {
                            setValue(value)
                        }} options={autofocusNoSpellcheckerOptions} getLineAndCursor={getLineAndCursorCallback} id="post-body" style={{textAlign: 'left'}} />
                    </div>

                    <div className="container mb-3 text-right">
                        <a className="btn" id="editPost" style={{backgroundColor: "#1b1c1d", color: 'white'}} onClick={() => {
                            handlePostEdit()
                        }}>Edit</a>
                    </div>
                </div>
                <div className="col-lg-2"></div>
                <hr />
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    );
}

export default Edit;