import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLinks } from '../../App';
import * as Icon from 'react-bootstrap-icons';
import logo from '../../assets/stemSocialLogo.webp'
import './navbar.css';

function NavbarCon() {
    return (
        <Navbar collapseOnSelect expand="lg" style={{backgroundColor: "#1b1c1d", color: '#fff', padding: "3vh 2em", maxHeight: '15vh', position: 'sticky', top: '0', zIndex: '10000'}}>
            <Navbar.Brand href="/"> <img src={logo} alt="logo" className="logo-img" style={{width: '8vw', height: '8vh'}} loading="lazy" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{color: 'white'}}>
                <Icon.List size={50} />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav" style={{
                    backgroundColor: "#1b1c1d",
                    width: '100%',
                    listStyleType: 'none'
                }}>           
                <Nav className="ms-auto">
                    <li className="nav-item">
                        <a className="nav-link text-white" aria-current="page" href="/">Home < Icon.HouseFill /></a>
                    </li>
                </Nav>
                <NavLinks id="protected-links"  />
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavbarCon;