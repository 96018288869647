import React from "react";
import ops from "../services/hiveOps"
import { notification } from 'antd';

import {keychain, isKeychainInstalled, hasKeychainBeenUsed} from '@hiveio/keychain'
import QRious from 'qrious';
import { PublicKey, Signature, hash } from '@hiveio/hive-js/lib/auth/ecc';
import { HasClient } from 'hive-auth-client';

const client = new HasClient('hive-auth.arcange.eu', '', true);

// Your application information
const APP_META = {name:"StemSocial", description:"Generic Hive frontend for all content", icon:undefined}

const hive = require("@hiveio/hive-js")

async function loginOp(username, key) {
    try {
        const signIn = await ops.getToken(username, key);
        await callback(signIn);        
    } catch (error) {
        console.log(error)
        notification.open({
            //message: 'Notification Title',
            description:
            'Error encountered',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
    }
}
async function callback(data) {
    if (data.auth == true) {
        localStorage.setItem('token', data.token)
        localStorage.setItem('username', data.username)
        localStorage.setItem('id', data.id)
        localStorage.setItem('ajbhs', data.password)
        localStorage.setItem('keychain', 'no')
        localStorage.setItem('keychainHas', 'no')

        window.location.replace('/')
    }
}
async function keychainLoginOp(username) {
    try{
        const accountData = await hive.api.getAccountsAsync([username])

        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', username, 5,  'test memo', 'HIVE')
        if(isKeychainInstalled) {
            const fetchMemo = await ops.fetchMemo(username)
            console.log(fetchMemo)
            keychainCallback(fetchMemo)
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else if(notInstalled) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please install Keychain',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                //console.log(error.message)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
    } catch (error) {
        console.log(error)
        notification.open({
            //message: 'Notification Title',
            description:
            'Error encountered',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
    }
}
async function keychainCallback(memo) {
    const theUsername = memo.username
    const encoded = memo.encoded

    let successMessage = ''

    const res = await keychain(window, 'requestSignBuffer', theUsername, encoded, 'Posting')

    if (res.success) {
        successMessage = res.success
        if (successMessage) {
            const data = await ops.keychainCallback(successMessage, theUsername)
            callback2(data)
        }
    }
}
async function callback2(data) {
    if (data.auth == true) {
        localStorage.setItem('token', data.token)
        localStorage.setItem('username', data.username)
        localStorage.setItem('id', data.id)
        localStorage.setItem('keychain', data.keychain)
        localStorage.setItem('keychainHas', 'no')

        window.location.replace('/')
    }
}
async function keychainHasLoginOp(username) {

    const challenge = '';

    const auth = {
        username,
        token: undefined,
        expire: undefined,
        key: undefined,
    };

    const challengeData = {
        key_type: 'posting',
        challenge,
    };
    
    const userAccount = await hive.api.getAccountsAsync([username])

    const verifyChallenge = (challenge, data) => {
        // Validate signature against account public key
        const sig = Signature.fromHex(data.challenge);
        const buf = hash.sha256(challenge, null, 0);
        return sig.verifyHash(buf, PublicKey.fromString(data.pubkey));
    };

    const handleAuthPending = (message) => {
        const {
            account, expire, key, uuid,
        } = message;
        const now = new Date().getTime();
        if (now < expire) {
            const authPayload = {
                uuid,
                account,
                key,
                host: 'wss://hive-auth.arcange.eu',
            };

            const authUri = `has://auth_req/${btoa(JSON.stringify(authPayload))}`;

            console.log('Hive Auth: Generating QR code');
            const qrLinkElement = document.getElementById('hiveauth-qr-link');
            const qrElement = document.getElementById('hiveauth-qr');
            const QR = new QRious({
                element: qrElement,
                background: 'white',
                backgroundAlpha: 0.8,
                foreground: 'black',
                size: 200,
            });
            QR.value = authUri;
            console.log(QR)
            qrLinkElement.href = authUri;
            qrLinkElement.style.display = 'flex'
            document.getElementById('qr-encloser').style.display = 'block'
        } else {
            console.warn('Hive Auth: token expired');
        }
    };

    const handleAuthSuccess = (message) => {
        const {
            data, uuid, authData: { token, key, expire },
        } = message;
        const { challenge: challengeResponse } = data;

        auth.token = token;
        auth.key = key;
        auth.expire =expire;

        console.log('Hive Auth: user has approved the auth request', challengeResponse);
        const verified = verifyChallenge(challenge, challengeResponse);

        if(verified) {
            console.log("Hive Auth: challenge succeeded");
            console.log(token, expire, uuid, challengeResponse.challenge)
            removeEventHandlers();

            localStorage.setItem('token', token)
            localStorage.setItem('username', username)
            localStorage.setItem('id', userAccount[0].id)
            localStorage.setItem('keychain', 'no')
            localStorage.setItem('keychainHas', 'yes')
            localStorage.setItem('hasKey', auth.key)
            localStorage.setItem('hasExpire', auth.expire)
            window.location.replace('/')
        } else {
            console.error("Hive Auth: challenge failed");

            removeEventHandlers();
            return {
                success: false,
                error: 'hiveauthservices.challengeValidationFailed'
            };
        }
    };

    const handleAuthFailure = (message) => {
        const { uuid } = message;
        console.warn('Hive Auth: user has rejected the auth request', uuid);
        removeEventHandlers();
        return {
            success: false,
            error: 'hiveauthservices.userRejectedRequest',
        };
    };

    const handleRequestExpired = (error) => {
        console.error('Hive Auth: server returned an error during authentication', error.message);

        removeEventHandlers();

        return {
            success: false,
            error: 'hiveauthservices.requestExpired',
        };
    };

    const handleAttachFailure = (error) => {
        console.error('Hive Auth: lost connection to server and failed re-attaching', error.message);
        removeEventHandlers();
        return {
            success: false,
            error: 'hiveauthservices.failedAttaching'
        };
    };

    const removeEventHandlers = () => {
        client.removeEventHandler('AuthPending', handleAuthPending);
        client.removeEventHandler('AuthSuccess', handleAuthSuccess);
        client.removeEventHandler('AuthFailure', handleAuthFailure);
        client.removeEventHandler('RequestExpired', handleRequestExpired);
        client.removeEventHandler('AttachFailure', handleAttachFailure);
    };



    client.addEventHandler('AuthPending', handleAuthPending);
    client.addEventHandler('AuthSuccess', handleAuthSuccess);
    client.addEventHandler('AuthFailure', handleAuthFailure);
    client.addEventHandler('RequestExpired', handleRequestExpired);
    client.addEventHandler('AttachFailure', handleAttachFailure);

    
    client.authenticate(
        auth,
        {
            name: 'StemSocial',
            description: 'StemSocial',
            icon: null,
        },
        challengeData
    );
}

async function keychainHasLoginStarter(username) {
    const authResponse = await keychainHasLoginOp(username)
}

function Login() {

  React.useEffect(() => { }, []);
  const startLoginNotification = () => {
    notification.open({
      //message: 'Notification Title',
      description:
        'Please wait while we try to log you in.',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };

  return (
    <div className="login" id="login-content">
        <div className="row login-form">
            <div className="col-lg-12 col-sm-12 col-md-12">
                <div className="card" id="login-card">
                    <div className="card-title text-center text-white" style={{padding: '2%', backgroundColor: "#1b1c1d"}}>
                        <h4>Login</h4>
                    </div>
                    <div className="card-body">
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" id="username" />
                        </div>
                        <div className="input-group mb-3" id="posting-key-sec" style={{display: 'flex'}}>
                            <input type="password" className="form-control" placeholder="Posting Key" aria-label="Posting Key" aria-describedby="basic-addon1" id="posting-key" />
                        </div>
                        {/*<div className="form-check mb-3 text-start">
                            <label className="form-check-label" htmlFor="useKeychain">
                                Use Hive Keychain with HiveAuth(Mobile Login)
                            </label>
                            <input className="form-check-input" type="checkbox" value="" id="useKeychainHas" onClick={() => {
                                if (document.getElementById('useKeychainHas').checked) {
                                    document.getElementById('keychain-login').style.display = 'none';
                                    document.getElementById('keychain-has-login').style.display = 'flex';
                                    document.getElementById('posting-login').style.display = 'none';
                                    document.getElementById('posting-key-sec').style.display = 'none';
                                } else {
                                    document.getElementById('keychain-login').style.display = 'none';
                                    document.getElementById('keychain-has-login').style.display = 'none';
                                    document.getElementById('posting-login').style.display = 'flex';
                                    document.getElementById('posting-key-sec').style.display = 'flex';
                                }
                                
                            }} />
                        </div>*/}
                        <div className="form-check mb-3 text-start">
                            <label className="form-check-label" htmlFor="useKeychain">
                                Use Hive Keychain
                            </label>
                            <input className="form-check-input" type="checkbox" value="" id="useKeychain" onClick={() => {
                                if (document.getElementById('useKeychain').checked) {
                                    document.getElementById('keychain-login').style.display = 'flex';
                                    document.getElementById('keychain-has-login').style.display = 'none';
                                    document.getElementById('posting-login').style.display = 'none';
                                    document.getElementById('posting-key-sec').style.display = 'none';
                                } else {
                                    document.getElementById('keychain-login').style.display = 'none';
                                    document.getElementById('keychain-has-login').style.display = 'none';
                                    document.getElementById('posting-login').style.display = 'flex';
                                    document.getElementById('posting-key-sec').style.display = 'flex';
                                }
                                
                            }} />
                        </div>
                        <div className="input-group mb-3" id="posting-login">
                            <button type="button" className="btn" style={{backgroundColor: "#1b1c1d", color: 'white'}}  id="login" onClick={() => {
                                startLoginNotification();
                                const username = document.getElementById('username').value
                                const posting = document.getElementById('posting-key').value
                                loginOp(username, posting)
                            }}>Login</button>
                        </div>
                        <div className="input-group mb-3" id="keychain-login" style={{display: 'none'}}>
                            <button type="button" className="btn" style={{backgroundColor: "#1b1c1d", color: 'white'}} id="login-key" onClick={() => {
                                startLoginNotification();
                                const username = document.getElementById('username').value
                                keychainLoginOp(username) 
                            }}>Keychain Login</button>
                        </div>
                        <div className="input-group mb-3" id="keychain-has-login" style={{display: 'none'}}>
                            <button type="button" className="btn" style={{backgroundColor: "#1b1c1d", color: 'white'}} id="login-key" onClick={() => {
                                startLoginNotification();
                                const username = document.getElementById('username').value
                                keychainHasLoginStarter(username) 
                            }}>Keychain Login(Mobile)</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Login;