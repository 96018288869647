import React from "react";
import { Routes, Route, useMatch } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import MarkdownIt from 'markdown-it';
import TurndownService from 'turndown';
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import Vote from './widgets/vote'
import Reblog from './widgets/reblog';
import Follow from './widgets/follow';
import ops from "../services/hiveOps"
import TimeAgo from 'timeago-react';
import { proxifyImageUrl } from "../utils/ProxifyUrl";
import { Row, Col, Tooltip } from 'antd';

import ProfileHeader from "./widgets/profileHeader";

const hive = require("@hiveio/hive-js")
let md = new MarkdownIt()
const turndownService = new TurndownService()

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};



function imageExists(image_url){

    var http = new XMLHttpRequest();
  
    http.open('HEAD', image_url, false);
    http.send();
  
    return http.status !== 404;
  
}

//let inscrybmde = new InscrybMDE()


const profile = getUrlParameter('user')
const screenWidth = window.screen.width + 'px'
let loggedInUser = localStorage.getItem('username') ? localStorage.getItem('username') : null


function Posts(v) {
    let props = v.props
    return (
        <Row>
            {props.posts.length > 0 && props.posts.map((post) => (
                <Col xxl={6} xl={8} lg={8} sm={24} md={12} style={{
                    padding: '1em',
                    fontFamily: '"Roboto", "Montserrat", Helvetica, Arial, sans-serif'
                }}>
                    <div className="card mb-3 post" key={post.permlink} data-author={post.author}>
                        <div className="card-body" style={{position: 'relative', width: '100%', overflow: 'hidden', height: '15em' }}>
                            <img className="card-img-top" src={post.cover} alt="Card image cap" style={{overflow:'hidden', display: 'block', width: '100%', height: 'auto'}} loading="lazy" />
                        </div>
                        <div className="card-body row element-with-scrollbar" style={{position: 'relative', width: '100%', overflowY: 'scroll', height: '7.5em' }}>

                            <div className="text-start col-md-3">
                                <img id="avatar" src={`https://images.hive.blog/u/${post.author}/avatar`} style={{borderRadius: '50%'}} loading="lazy" />
                            </div>
                                
                            <div className="text-start col-md-9">
                                <h5 className="card-title post-title-wrap" style={{fontSize: '1.25em'}}><a href={`/post?permlink=${post.permlink}&author=${post.author}`} style={{cursor: 'pointer !important', color: '#1b1c1d', textDecoration: 'none !important'}} className="post-title">{post.title}</a></h5>
                            </div>
                        </div>
                        <div className="card-body row" style={{position: 'relative', width: '100%', overflow: 'hidden', height: '7.5em' }}>
                            <p className="card-text" style={{marginTop: '5%', display: 'flex'}}>
                                <small className="text-muted" style={{
                                    width: '50%',
                                    textALign: 'left'
                                }}>
                                    <TimeAgo
                                        datetime={post.last_update}
                                    />
                                </small>

                                <small className="text-muted" style={{
                                    width: '50%',
                                    textALign: 'right'
                                }}><a href={"/p?user=" + post.author} style={{cursor: 'pointer !important', color: '#1b1c1d', textDecoration: 'none !important'}}>@{post.author}</a> </small>
                            </p>
                        </div>
                        <div className="card-footer post-footer-area row" style={{backgroundColor: "#1b1c1d"}}>
                            <Tooltip title="Vote">
                                <div className="vote-post text-white col" id={`${post.author}${post.permlink}`} style={{display: 'inherit', paddingTop: '1%'}}>
                                    <Vote props={{
                                        author: post.author,
                                        permlink: post.permlink,
                                        voted: post.voted,
                                        voter: loggedInUser
                                    }} />
                                </div>
                            </Tooltip>

                            <div className="view-post text-white col">
                                <Tooltip title="View Post">
                                    <a href={`/post?permlink=${post.permlink}&author=${post.author}`} style={{cursor: 'pointer !important'}} className="text-white">
                                    < Icon.Eye /></a>
                                </Tooltip>
                            </div>

                            <div className="post-comments text-white col">
                                <Tooltip title="Comments">
                                    <a href={`/post?permlink=${post.permlink}&author=${post.author}`} style={{cursor: 'pointer !important', textDecoration: 'none', display: 'inline-flex', marginTop: '0.5vh'}} className="text-white">< Icon.Chat /><small>{post.replyLength}</small></a>
                                </Tooltip>
                            </div>
                            
                            <Tooltip title="Reblog">
                                <div className="reblog-post text-white col">
                                    <Reblog props={{
                                        author: post.author,
                                        permlink: post.permlink,
                                        reblogged: post.reblogged,
                                        username: loggedInUser
                                    }} />
                                </div>
                            </Tooltip>

                            <div className="pending-payout text-white col" style={{fontSize: '10px', display: 'inline-flex', paddingTop: '1%'}}>
                                <Tooltip title="Payout">
                                    <b>{post.pendingPayout}</b>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </Col>
            ))}

            {props.posts.length === 0 && 
                <div className="col-lg-12 col-md-12 col-sm-12 post">
                    <div className="card mb-3" style={{margin: '5%'}}>
                        <div className="row no-gutters">
                            <div className="col-md-12 col-sm-12">
                                <div className="card-body row">
                                    <div className="text-center col-md-12">
                                        <h5 className="card-title post-title-wrap">User has not posted yet</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Row>
    )
}


function UserPosts() {
    const [posts, setPosts] = React.useState([]);
    let [loading, setLoading] = React.useState(true);
    let [color, setColor] = React.useState('#1b1c1d');
    let [followStatus, setFollowStatus] = React.useState(false);
    let [followCount, setFollowCount] = React.useState(0);
    let [coverImage, setCoverImage] = React.useState('')

    let refPosts = []
    let authorPosts = []
    let postImgHeight = '250px'

    async function sortContents(a) {
        if (a.length > 0) {
            await a.forEach(async d => {
                if (d.permlink !== undefined && d.author !== undefined) {
                    if (d.title.length > 0 && d.json_metadata.tags && (d.json_metadata.tags.includes('stemsocial') || d.json_metadata.tags.includes('hive-196387') || d.community.includes('hive-196387'))) {
                        let json = d.json_metadata
                        let title = d.title
        
                        let voted = false
                        let reblogged = false
        
                        if (loggedInUser) {
                            d.active_votes.forEach(v => {
                                if (v.voter === loggedInUser) {
                                    voted = true
                                }
                            })
                        }
        
                        
        
                        let summary = d.body
                        if (d.body.length > 100) {
                            summary = d.body.substring(0, 90)
                        }
                        summary = summary.replace(/<center>/g, '').replace(/<\/center>/g, '').replace(/<div className="text-justify">/g, '').replace(/<\/div>/g, '').replace(/<div className="pull-left">/g, '').replace(/<div className="pull-right">/g, '').replace(/<hr>/g, '')
                        if (json) {
                             await authorPosts.push({
                                title: title,
                                permlink: d.permlink,
                                author: d.author,
                                url: d.url,
                                last_update: d.created,
                                cover: json.image !== undefined && json.image.length > 0 ? proxifyImageUrl(json.image[0], true) : '/img/default_avatar_2.png',
                                category: json.tags !== undefined ? json.tags[0] : 'none',
                                postImgHeight,
                                summary: md.render(summary),
                                voted,
                                pendingPayout: d.is_paidout === true ? d.payout + " HBD" :  d.pending_payout_value,
                                reblogged,
                                replyLength: d.children
                            })
                        }
                    }
                }
            });
        }
    }

    React.useEffect(() => {

        async function getPosts() {
            let theAuthorPosts = await ops.getAccountPosts(profile, 'posts')
            console.log('a', theAuthorPosts)
           
            const [account] = await hive.api.getAccountsAsync([profile]);

            let cover = ''; //TODO:REPLACE

            let json = {}

            if (account.posting_json_metadata || account.json_metadata) {
                json = JSON.parse(account.posting_json_metadata || account.json_metadata)
            }

            if (json.profile && json.profile.cover_image !== undefined) {
                cover = json.profile.cover_image;
            }

            if (cover.length > 0) {
                setCoverImage(cover)
            }
             
            if (theAuthorPosts.length > 0) {
                await sortContents(theAuthorPosts)
            }

            document.getElementById('hideOnLoad').style.display = 'none'
            setPosts(authorPosts)

            console.log({ p: posts })

            async function checkFollowing(follower, user) {
                let checkFollowOp = await ops.checkFollowing(follower, user);
                return checkFollowOp;
            }

            async function getFollowCount(user) {
                let getCount = await ops.getFollowCount(user);
                return getCount;
            }

            const followCountOp = await getFollowCount(profile)

            setFollowCount(followCountOp.follower_count)

            const followStatus = await checkFollowing(loggedInUser, profile)

            setFollowStatus(followStatus)
        }

        getPosts()
    }, []);

    let followDisplay = 'block'

    if (loggedInUser === profile) {
        followDisplay = 'none'
    }
    

    function createMarkup(params) {
        return {__html: params};
    }

    return (
        <div className="posts" id="page-content">
            <div className="row trending-posts">
                <ProfileHeader props={{
                    followStatus,
                    followCount,
                    coverImage,
                    followDisplay
                }} />
                <div className="sweet-loading col-lg-12 col-md-12 col-sm-12" id="hideOnLoad" style={{marginTop: '5%'}}>
                    <PulseLoader color={color} loading={loading} css={override} size={50} />
                </div>

                <Posts props={{
                    posts
                }}/>
            </div>
        </div>
    );
}

export default UserPosts;