import React  from "react";
import * as Icon from 'react-bootstrap-icons';
import MarkdownIt from 'markdown-it';
import TurndownService from 'turndown';
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import ops from "../services/hiveOps"
import {keychain, isKeychainInstalled, hasKeychainBeenUsed} from '@hiveio/keychain'
import Vote from './widgets/vote';
import Reblog from './widgets/reblog';
import Select from 'react-select-me';
import TimeAgo from 'timeago-react';
import remarkableStripper from '../utils/RemarkableStripper'
import sanitize from 'sanitize-html';
import {htmlDecode} from '../utils/htmlDecode'
import { notification } from 'antd';

// IMPORTANT If you want to provide default styles you have to import them
import 'react-select-me/lib/ReactSelectMe.css';

const postOptions = [
    { value: 'trending', label: 'Trending' },
    { value: 'hot', label: 'Hot' },
    { value: 'new', label: 'New' },
    { value: 'feed', label: 'Feed' }
];


const hive = require("@hiveio/hive-js")
let md = new MarkdownIt()
const turndownService = new TurndownService()



// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

let token = localStorage.getItem('token')
let loggedInUser = localStorage.getItem('username') ? localStorage.getItem('username') : null

function imageExists(image_url){

    var http = new XMLHttpRequest();
  
    http.open('HEAD', image_url, false);
    http.send();
  
    return http.status !== 404;
  
}

const Jumbotron = function () {
    if (token === null) {
      return (
        <div class="p-5 text-white" style={{backgroundColor: '#1b1c1d'}}>
            <h4>Get cryptocurrency rewards on <a href="https://hive.io" target="_blank" style={{color: '#e9425f', textDecoration: 'none !important'}}>Hive</a> by creating content on our website. Click the links below to login or signup</h4>
            <p>Click the <a href="https://hive.io" target="_blank" style={{color: '#e9425f', textDecoration: 'none !important'}}>link</a> to learn more about <a href="https://hive.io" target="_blank" style={{color: '#e9425f', textDecoration: 'none !important'}}>Hive</a></p>
            <p>If you are new to <a href="https://hive.io" target="_blank" style={{color: '#e9425f', textDecoration: 'none !important'}}>Hive</a> checkout the <a href="/p?user=newbies-guide" target="_blank" style={{color: '#e9425f', textDecoration: 'none !important'}}>newbies guide for help</a></p>
            <div>
                <span style={{margin: '3%'}}><a href="/login" className="btn btn-lg" style={{backgroundColor: "white", color: '#1b1c1d'}}>Login</a></span>
                <span style={{margin: '5% 1%'}}><a href="https://signup.hive.io" className="btn btn-lg" style={{backgroundColor: "white", color: '#1b1c1d'}} target="_blank">Signup</a></span>
            </div>
        </div>
      )
    }
    
    if (token !== null) {
        return null
    }
}

function Communities() {
    let [middlePosts, setMiddlePosts] = React.useState([]);
    let [loading, setLoading] = React.useState(true);
    let [color, setColor] = React.useState('#1b1c1d');
    let [coverImage, setCoverImage] = React.useState('/img/default_avatar.png')
  
    let refPosts = []
    let communitiesArr = []

    async function sortContents(a) {
        const subscriptions = await ops.getUserSubscriptions(loggedInUser)
        console.log(subscriptions)
        a.forEach(async c => {
            let admins = !c.admins ? ['No admins provided'] : c.admins
            c.adminStr = admins.join()
            c.joinDisplay = 'inline-block'
            c.leaveDisplay = 'none'
            await subscriptions.forEach(async sub => {
                if (sub.includes(c.name)) {
                    c.joinDisplay = 'none'
                    c.leaveDisplay = 'inline-block'
                }
            })
            communitiesArr.push(c)
        });
    }

    React.useEffect(() => {
        async function getPosts() {
            try {
                let theCommunities = await ops.getAllCommunities()
                console.log(theCommunities)
                await sortContents(theCommunities)
                setMiddlePosts(communitiesArr)
                document.getElementById('hideOnLoad').style.display = 'none'
            } catch (error) {
                console.log(error)
            }
        }


        getPosts()
    }, []);

    function createMarkup(params) {
        return {__html: params};
    }

    async function joinCommunity(name, user) {
        notification.open({
            //message: 'Notification Title',
            description:
            'Joining',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
        const operation = ["custom_json",
        {
          "required_auths": [],
          "required_posting_auths": [user],
          "id": "community",
          "json": JSON.stringify(["subscribe", {"community":name}])
        }]

        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', loggedInUser , 5,  'test memo', 'HIVE')
        
        const keychainStatus = localStorage.getItem('keychain')
        console.log('Dont be silly join')

        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {
                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', loggedInUser , [operation], 'Posting');
                if (success) {
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        `Subscription to ${name} successful`,
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    console.log(success)
                    document.getElementById(`${name}${loggedInUser}leave`).style.display = 'inline-block'
                    document.getElementById(`${name}${loggedInUser}join`).style.display = 'none'
                }
                
            } catch (error) {
                console.log(error)
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {

                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = localStorage.getItem('ajbhs')
                    const theOp = await hive.broadcast.sendAsync({
                        operations: [operation]
                      }, {posting: dybnjg});
                      console.log(theOp)
                      notification.open({
                        //message: 'Notification Title',
                        description:
                        'Subscription successful',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                      document.getElementById(`${name}${loggedInUser}leave`).style.display = 'inline-block'
                      document.getElementById(`${name}${loggedInUser}join`).style.display = 'none'
                } catch (error) {
                    console.log(error)
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Error encountered',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    
                }
                
            }
        }
    }

    async function leaveCommunity(name, user) {
        notification.open({
            //message: 'Notification Title',
            description:
            'Leaving',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
        const operation = ["custom_json",
        {
          "required_auths": [],
          "required_posting_auths": [user],
          "id": "community",
          "json": JSON.stringify(["unsubscribe", {"community":name}])
        }]

        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', loggedInUser , 5,  'test memo', 'HIVE')
        
        const keychainStatus = localStorage.getItem('keychain')

        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {
                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', loggedInUser , [operation], 'Posting');
                if (success) {
                    console.log(success)
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        `Successfully left ${name}`,
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    document.getElementById(`${name}${loggedInUser}leave`).style.display = 'none'
                    document.getElementById(`${name}${loggedInUser}join`).style.display = 'inline-block'
                }
                
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {

                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = localStorage.getItem('ajbhs')
                    const theOp = await hive.broadcast.sendAsync({
                        operations: [operation]
                      }, {posting: dybnjg});
                      console.log(theOp)
                      document.getElementById(`${name}${loggedInUser}leave`).style.display = 'none'
                      document.getElementById(`${name}${loggedInUser}join`).style.display = 'inline-block'
                } catch (error) {
                    console.log(error)
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Error encountered',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });                    
                }
            }
        }
    }

    return (
        <div className="posts" id="page-content">
            <Jumbotron />
            <div className="row trending-posts">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="sweet-loading text-center" id="hideOnLoad" style={{marginTop: '15%'}}>
                        <p>This might take a while please wait</p>
                        <PulseLoader color={color} loading={loading} css={override} size={50} />
                    </div>
                    <div className="card-body">
                        <h5 className="new post-title"  style={{color: '#1b1c1d', textDecoration: 'none !important'}}>Communities</h5>
                    </div>
                    {<div id="postsContainer" className="row" style={{padding: '3%'}}>
                        {middlePosts.map((community) => (
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="card" key={community.id} data-community-name={community.name} style={{ margin: '10px', width: '90%' }}>
                                    <div className="card-body">                                        
                                        <div className="text-start">
                                            <h5 className="card-title post-title-wrap"><a href={`/category?name=${community.name}`} style={{cursor: 'pointer !important', color: '#1b1c1d', textDecoration: 'none !important'}} className="post-title">{community.title}</a></h5>
                                        </div>                                        
                                        <div className="text-start">
                                            <p className="card-text" style={{marginTop: '2%'}}>
                                                {community.about}
                                            </p>
                                        </div>

                                        <p className="card-text text-start row" style={{marginTop: '2%', width: '100%'}}>
                                            <span className="badge badge-dark text-muted col">
                                                <b>Admins</b><br />
                                                <small>
                                                    {community.adminStr}
                                                </small>
                                            </span>
                                            <span className="badge badge-dark text-muted col">
                                                <b>Subscribers</b><br />
                                                <small>
                                                    {community.subscribers}
                                                </small>
                                            </span>
                                            <span className="badge badge-dark text-muted col">
                                                <b>Active Posters</b><br />
                                                <small>
                                                    {community.num_authors}
                                                </small>
                                            </span>
                                            <span className="badge badge-dark text-muted col">
                                                <b>Pending Payout</b><br />
                                                <small>
                                                    {community.sum_pending}
                                                </small>
                                            </span>
                                        </p>
                                    </div>
                                    
                                    <div className="card-footer post-footer-area row" style={{backgroundColor: "#1b1c1d"}}>
                                        <div className="pending-payout text-white col text-end" style={{fontSize: '10px', display: 'inline-flex', paddingTop: '1%'}}>
                                            <a className="btn btn-sm text-end" id={`${community.name}${loggedInUser}join`} style={{color: "#1b1c1d", backgroundColor: 'white', display: community.joinDisplay}} onClick={() => {joinCommunity(community.name, loggedInUser)}}>JOIN</a>
                                            <a className="btn btn-sm text-end" id={`${community.name}${loggedInUser}leave`} style={{color: "#1b1c1d", backgroundColor: 'white', display: community.leaveDisplay}} onClick={() => {leaveCommunity(community.name, loggedInUser)}}>LEAVE</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default Communities;