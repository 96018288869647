import React from 'react';

const hive = require("@hiveio/hive-js")

function ShowCommentCount(author, permlink) {
    const [repliesLength, setRepliesLength] = React.useState(0)

    React.useEffect(() => {
        const fetchData = async () => {
            let postReplies = await hive.api.getContentRepliesAsync(author, permlink);
            setRepliesLength(postReplies.length)
        }

        fetchData();
    })
    
    return (
        <span>{repliesLength}</span>
    )
}

export default ShowCommentCount;