import React from 'react';
import { Row, Col, Card, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './teamMembers.css'

const { Meta } = Card;

const TeamMembers = () => {
  const teamMembersData = [
    {
      name: 'Benjamin Fuks',
      role: 'Professor',
      image: 'https://stem.openhive.network/images/team/bfuks.png',
      summary: 'Benjamin is professor at Sorbonne Université (Paris) and works on theoretical particle and astroparticle physics. His research interests span perturbative chromodynamics, beyond the Standard Model phenomenology, LHC and future collider physics, dark matter and the development of computing tools for high-energy physics. He discovered Hive (known as Steem back in the days) in July 2016 and now actively works to make it a novel medium for science communication used by the scientific community.',
    },
    {
      name: 'Andrew Mobbs',
      role: 'Writer',
      image: 'https://stem.openhive.network/images/team/amobbs.png',
      summary: 'Writer, composer and educator by trade, Andrew is also a science autodidact, with focus on biology and phylogenetics. After authoring multiple series on the STEEM blockchain since 2017, he discovered and joined STEMsocial with the shared passion of its founders to take STEM communication to a new level.',
    },
    // Add more team members here
  ];

  return (
    <div className="team-members">
      <Row gutter={16} style={{margin: 'auto'}}>
        {teamMembersData.map((member, index) => (
          <Col xs={24} sm={12} md={12} key={index}>
            <Card
              cover={<><Avatar size={150} src={member.image} icon={<UserOutlined />} style={{margin: '3vh auto'}} /><hr /></>}
            >
              <Meta title={member.name} description={member.role} />
              <hr />
              <p className="member-summary" style={{textAlign: 'left'}}>{member.summary}</p>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TeamMembers;
