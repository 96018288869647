
import { PublicKey, Signature, hash } from '@hiveio/hive-js/lib/auth/ecc';
import { HasClient } from 'hive-auth-client';
import { notification } from 'antd';
const client = new HasClient('hive-auth.arcange.eu', '', true);

let auth = {
    username: localStorage.getItem('username'),
    token: localStorage.getItem('token'),
    expire: undefined,
    key: localStorage.getItem('hasKey'),
};

const HiveAuthUtils = {    
    broadcastHiveAuth : (operations, type, callbackFn) => {
        const handleSignPending = () => {
            console.log('hiveauthservices.broadcastInstructions');

            // Put modal trigger/notification here
            notification.open({
                //message: 'Notification Title',
                description:
                'Log in to Keychain app to sign this transaction',
                onClick: () => {
                console.log('Notification Clicked!');
                },
            });
        };

        const handleSignSuccess = (message) => {
            console.log('Hive Auth: broadcast successful', message);
            callbackFn({
                success: true,
            });
            removeEventHandlers();
        };

        const handleSignFailure = (error) => {
            console.warn('Hive Auth: broadcast failed', error);
            callbackFn({
                success: false,
                error: error.error || error.message,
            });
            removeEventHandlers();
        };

        const handleSignError = (error) => {
            console.warn('Hive Auth: server returned an error during broadcast', error);
            callbackFn({
                success: false,
                error: error.error || error.message,
            });
            removeEventHandlers();
        };

        const handleRequestExpired = (error) => {
            console.error('Hive Auth: server returned an error during broadcast', error.message);

            callbackFn({
                success: false,
                error: 'hiveauthservices.requestExpired',
            });

            removeEventHandlers();
        };

        const handleAttachFailure = (error) => {
            console.error('Hive Auth: lost connection to server and failed re-attaching', error.message);
            callbackFn({
                success: false,
                error: 'hiveauthservices.failedAttaching',
            });
            removeEventHandlers();
        };

        const removeEventHandlers = () => {
            client.removeEventHandler('SignPending', handleSignPending);
            client.removeEventHandler('SignSuccess', handleSignSuccess);
            client.removeEventHandler('SignFailure', handleSignFailure);
            client.removeEventHandler('SignError', handleSignError);
            client.removeEventHandler('RequestExpired', handleRequestExpired);
            client.removeEventHandler('AttachFailure', handleAttachFailure);
        };

        client.addEventHandler('SignPending', handleSignPending);
        client.addEventHandler('SignSuccess', handleSignSuccess);
        client.addEventHandler('SignFailure', handleSignFailure);
        client.addEventHandler('SignError', handleSignError);
        client.addEventHandler('RequestExpired', handleRequestExpired);
        client.addEventHandler('AttachFailure', handleAttachFailure);
        client.broadcast(auth, type, operations);
    }
}

export default HiveAuthUtils;