import {keychain, isKeychainInstalled, hasKeychainBeenUsed} from '@hiveio/keychain'
import axios from 'axios';
import { PublicKey, Signature, hash } from '@hiveio/hive-js/lib/auth/ecc';
import { HasClient } from 'hive-auth-client';
import Modal from 'react-modal';
import HiveAuthUtils from '../utils/HiveAuthUtils';
import { detect, detectAll } from '@ocelotbot/tinyld'
import { notification } from 'antd';

const client = new HasClient('hive-auth.arcange.eu', '', true);

// Your application information
const APP_META = {name:"stemsocial", description:"Generic Hive frontend for all content", icon:undefined}

const auth = {
    username: undefined,
    token: undefined,
    expire: undefined,
    key: undefined,
};

const hive = require("@hiveio/hive-js")
var jwt = require('jsonwebtoken');

const errorLoginNotification = () => {
    notification.open({
      //message: 'Notification Title',
      description:
        'Error while trying to log you in. Please try again',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
};

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

const tagsList = ['stemsocial', 'science', 'physics', 'biology', 'chemistry', 'geology', 'technology', 'engineering', 'mathematics', 'economics'];
//const tagsList = shuffle(tagsListInit)

const ops = {
    getTrending: async function() {
        var query = { limit : 3, tag : "hive" };
        hive.api.getDiscussionsByTrending30(query, function(err, data) {
            console.log(err, data);
        });
    },
    getToken: async function (username, password) {
        let authData = {
            auth: false,
            token: 'null',
            username: 'null',
            id: 'null',
            password: 'null'
        }
        const user = await hive.api.getAccountsAsync([username]);

        const pubWif = user[0].posting.key_auths[0][0];
    
            // check for the validity of the posting key
    
        if (hive.auth.isWif(password)) {
            // check if the public key tallies with the private key provided
            
            const Valid = hive.auth.wifIsValid(password, pubWif);
    
            if(Valid){
                // create token and store in token variable
    
                var token = await jwt.sign({ id: user._id }, 'config#2*Tm34', {
                    expiresIn: 86400
                });
                
                // if user authentication is successful send auth confirmation, token and user data as response

                authData.auth = true
                authData.token = token
                authData.username = user[0].name
                authData.id = user[0].id
                authData.password = password

                return authData
            } else {
                authData.auth = false
                authData.token = 'null'
                authData.username = 'null'
                authData.id = 'null'
                errorLoginNotification()
                return authData
            }
        } else {
            authData.auth = false
            authData.token = 'null'
            authData.username = 'null'
            authData.id = 'null'
            errorLoginNotification()
            return authData
        }
    },
    fetchMemo: async function(username) {
        let data = await hive.api.getAccountsAsync([username]);
        let pub_key = data[0].posting.key_auths[0][0];
        let memoData = {
            username,
            encoded
        };

        if (data.length === 1)
        {
            const speakBountiesWif = '5Hqg424NMKGe8PtkzmhCc5no2cCRYJCPq6b7YQwTJ28mj3wKYgx'

            var encoded = await hive.memo.encode(speakBountiesWif, pub_key, `log user in`);
            memoData.username = username
            memoData.encoded = encoded
            return memoData
        }
    },
    keychainCallback: async function (message, username) {
        let authData = {
            auth: false,
            token: 'null',
            username: 'null',
            id: 'null',
            keychain: 'null'
        }
        if (message) {
            var token = jwt.sign({ id: username }, 'config#2*Tm34', {
                expiresIn: 86400
            });
            const user = await hive.api.getAccountsAsync([username])
            authData.auth = true
            authData.token = token
            authData.username = user[0].name
            authData.id = user[0].id
            authData.keychain = 'yes'
            return authData
        }

    },
    voteContent: async function (author, permlink, voter, weight) {

        const vote = {
            author,
            permlink,
            voter,
            weight
        };

        const operation = ['vote', vote]

        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', author, 5,  'test memo', 'HIVE')
        
        const keychainStatus = localStorage.getItem('keychain')
        const keychainHasStatus = localStorage.getItem('keychainHas')

        try {
            if(keychainHasStatus === 'yes' && keychainStatus === 'no') {
                HiveAuthUtils.broadcastHiveAuth([operation],
                    'posting',
                    (response) => {
                        console.log('HAS broadcast response', response);
                        if (!response.success) {
                            console.log(response.error);
                            console.log('Error')
                            notification.open({
                                //message: 'Notification Title',
                                description:
                                'Error encountered',
                                onClick: () => {
                                console.log('Notification Clicked!');
                                },
                            });
                            document.getElementById(`${author}${permlink}voter`).style.display = 'inline-flex'
                            document.getElementById(`${author}${permlink}loader`).style.display = 'none'
                        } else {
                            document.getElementById(`${author}${permlink}rvote`).style.display = 'inline-flex'
                            document.getElementById(`${author}${permlink}loader`).style.display = 'none'
                        }
                    })
            }
            
            if(isKeychainInstalled && keychainStatus === 'yes') {
                // do your thing

                try {
                    const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', voter, [operation], 'Posting');

                    if (success) {
                        return {success: 'yes'}
                    }

                    // User cancelled
                    else if (cancel) {
                        document.getElementById(`${author}${permlink}voter`).style.display = 'inline-flex'
                        document.getElementById(`${author}${permlink}loader`).style.display = 'none'
                    }
                    
                } catch (error) {
                    console.log(error)
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Error encountered',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    document.getElementById(`${author}${permlink}voter`).style.display = 'inline-flex'
                    document.getElementById(`${author}${permlink}loader`).style.display = 'none'
                }
            }
            // User didn't cancel, so something must have happened
            else if(!cancel) {
                if(notActive) {
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Please allow Keychain to access this website',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                } else if(keychainHasStatus === 'no') {
                    document.getElementById(`${author}${permlink}voter`).style.display = 'inline-flex'
                    document.getElementById(`${author}${permlink}loader`).style.display = 'none'
                    try {
                        console.log('Posting lo le shey')
                        const dybnjg = localStorage.getItem('ajbhs')
                        const sendPost = await hive.broadcast.voteAsync(dybnjg, voter, author, permlink, weight)
                        if (sendPost.id) {
                            return {success: 'yes'}
                        }
                    } catch (error) {
                        console.log(error)
                        notification.open({
                            //message: 'Notification Title',
                            description:
                            'Error encountered',
                            onClick: () => {
                            console.log('Notification Clicked!');
                            },
                        });
                        document.getElementById(`${author}${permlink}voter`).style.display = 'inline-flex'
                        document.getElementById(`${author}${permlink}loader`).style.display = 'none'
                        return error;
                    }
                }
            } 
        } catch (error) {
            return error
        }

        
    },
    checkVote: async function (author, permlink, username) {
        const post = await hive.api.getContentAsync(author, permlink);
        let voted = false;

        if (username !== null) {
            await post.active_votes.forEach(y => {
                if (y.voter === username) {
                    voted = true
                }
            });
        }

        return voted
                
    },
    reblogContent: async function (author, permlink, username) {
        const operation = [
            "custom_json",
            {
                "required_auths": [],
                "required_posting_auths": [username],
                "id": "reblog",
                "json": JSON.stringify([
                    "reblog", {
                        "account": username, 
                        "author": author,
                        "permlink": permlink
                    }
                ])
            }
        ]

        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', author, 5,  'test memo', 'HIVE')
        
        const keychainStatus = localStorage.getItem('keychain')

        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {
                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', username, [operation], 'Posting');

                if (success) {
                    return {success: 'yes'}
                }

                // User cancelled
                else if (cancel) {
                    return {success: 'no'}
                }
                
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = localStorage.getItem('ajbhs')
                    const json = JSON.stringify([
                        "reblog", {
                            "account": username, 
                            "author": author,
                            "permlink": permlink
                        }
                    ])
                    const sendPost = await hive.broadcast.customJsonAsync(dybnjg, [], [username], 'reblog', json)
                    if (sendPost.id) {
                        return {success: 'yes'}
                    }
                } catch (error) {
                    console.log(error)
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Error encountered',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });                    
                }
            }
        }
    },
    followUser: async function (userToFollow, follower) {
        const operation = [
                "custom_json",
                {
                  "required_auths": [],
                  "required_posting_auths": [follower],
                  "id": "follow",
                  "json": JSON.stringify([
                    "follow", {
                        "follower":follower, 
                        "following":userToFollow,
                        "what":["blog"]
                    }
                  ])
                }
            ]

        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', follower, 5,  'test memo', 'HIVE')
        
        const keychainStatus = localStorage.getItem('keychain')

        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {
                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', follower, [operation], 'Posting');

                if (success) {
                    return {success: 'yes'}
                }

                if (cancel) {
                    return {success: 'no'}
                }
                
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = localStorage.getItem('ajbhs')
                    const json = JSON.stringify([
                        'follow', {follower: follower, following: userToFollow, what: ["blog"]}
                    ])
                    const sendFollow = await hive.broadcast.customJsonAsync(dybnjg, [], [follower], 'follow', json)
                    if (sendFollow.id) {
                        return {success: 'yes'}
                    }
                } catch (error) {
                    console.log(error)
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Error encountered',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });                    
                }
            }
        }
    },
    checkFollowing: async function (follower, following) {
        async function runCheck () {
            let finalFollowArr = [];
            let startFollowers = ''
            const followCount = await hive.api.getFollowCountAsync(following)
            let followingLoop = await hive.api.getFollowersAsync(following, startFollowers, 'blog', 1000);
            finalFollowArr = finalFollowArr.concat(followingLoop)
            do {
                const lastFollower = followingLoop ? followingLoop[followingLoop.length - 1] : {follower: ''}

                if (lastFollower && (lastFollower !== null || lastFollower !== undefined) && lastFollower) {
                    startFollowers = lastFollower.follower
                }
                followingLoop = await hive.api.getFollowersAsync(following, startFollowers, 'blog', 1000);
                finalFollowArr = finalFollowArr.concat(followingLoop)
            }
            while  (finalFollowArr.length !== followCount.follower_count)
            let followed = false;

            if (finalFollowArr.length === followCount.follower_count) {
                for (let i = 0; i < finalFollowArr.length; i++) {
                    if (finalFollowArr[i].follower === follower) {
                        followed = true
                    }
                }
                return followed
            }
        }

        const getStatus = await runCheck()
        return getStatus;
    },
    getPopularCommunities: async function () {
        let popularCommunities = []

        let commies = await axios.post(
            'https://api.hive.blog', 
            JSON.stringify({
                "jsonrpc":"2.0", "method":"bridge.list_communities", "params":{"limit": 25, sort: 'rank'}, "id":1
            })
        )
        
        async function sortCommies(v) {
            for (let i = 0; i <= v.length - 1; i++) {
                let oneCommie = v[i];

                if (oneCommie.lang === 'en') {
                    popularCommunities.push({name: oneCommie.name, title: oneCommie.title, about: oneCommie.about})
                }

                if (i === v.length - 1) {
                    return popularCommunities
                }
            }
        }

        const communities = await sortCommies(commies.data.result)
        return communities;
    },
    getAllCommunities: async function () {
        let popularCommunities = []

        let commies = []

        let initCommiesArr = await axios.post(
            'https://api.hive.blog', 
            JSON.stringify({
                "jsonrpc":"2.0", "method":"bridge.list_communities", "params":{"limit": 100, sort: 'rank'}, "id":1
            })
        )

        let initCommies = initCommiesArr.data.result

        commies = commies.concat(initCommies)
        let lastComm = initCommies[initCommies.length - 1]
        
        async function retrieveCommunities(indexName) {
            let getCommiesArr = await axios.post(
                'https://api.hive.blog', 
                JSON.stringify({
                    "jsonrpc":"2.0", "method":"bridge.list_communities", "params":{"last": indexName, "limit": 100, sort: 'rank'}, "id":1
                })
            )
    
            let getCommies = getCommiesArr.data.result

            return getCommies
    
        }

        let theComms = await retrieveCommunities(lastComm.name)
        commies = commies.concat(theComms)
        do {
            lastComm = theComms[theComms.length -1]
            theComms = await retrieveCommunities(lastComm.name)
            commies = commies.concat(theComms)
        }
        while (theComms.length > 0)

        const communities = commies
        return communities;
    },
    getFollowCount: async function (user) {
        const followCount = await hive.api.getFollowCountAsync(user)
        return followCount;
    },
    getAccountPosts: async function (user, sort) {
        let posts = await axios.post(
            'https://api.hive.blog', 
            JSON.stringify({
                "jsonrpc":"2.0", "method":"bridge.get_account_posts", "params":{"sort": sort, "account": user, "limit": 40}, "id":1
            })
        )

        return posts.data.result
    },
    getWalletData: async function(profile) {
        const username = profile

        const getAccount = await hive.api.getAccountsAsync([username]);

        const vestingShares = getAccount["0"].vesting_shares;
        const delegatedVestingShares = getAccount["0"].delegated_vesting_shares;
        const receivedVestingShares = getAccount["0"].received_vesting_shares;

        const dynamicGlobalProps = await hive.api.getDynamicGlobalPropertiesAsync();
        const totalVestingShares = dynamicGlobalProps.total_vesting_shares;
        const totalVestingFund = dynamicGlobalProps.total_vesting_fund_hive;

        const hivePower = hive.formatter.vestToHive(vestingShares, totalVestingShares, totalVestingFund);
        const delegatedHivePower = hive.formatter.vestToHive((receivedVestingShares.split(' ')[0] - delegatedVestingShares.split(' ')[0]) + ' VESTS', totalVestingShares, totalVestingFund);


        const totalHive = parseFloat(getAccount[0].balance.split(' ')[0]) + hivePower


        const exchangeRate = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=hive%2Chive_dollar&vs_currencies=usd');

        const hiveInDollars = totalHive * exchangeRate.data.hive.usd
        const hbdInDollars = parseFloat(getAccount[0].balance.split(' ')[0]) * exchangeRate.data.hive_dollar.usd

        const estimatedAccountValue = hiveInDollars + hbdInDollars

        // vesting withdrawal manager

        const pendingVestWithdraw = getAccount["0"].to_withdraw
        const nextVestingWithdrawal = getAccount[0].next_vesting_withdrawal

        const vestingWithdrawalRate = getAccount[0].vesting_withdraw_rate

        const vestingWithdrawalHive = hive.formatter.vestToHive(vestingWithdrawalRate, totalVestingShares, totalVestingFund).toFixed(3);

        let pendingVestWithdrawStat

        if (pendingVestWithdraw > 0) {
            pendingVestWithdrawStat = true
        } else {
            pendingVestWithdrawStat = false
        }

        let savingsWithdrawStat
        const pendingSavingsWithdrawal = getAccount[0].savings_withdraw_requests

        if (pendingSavingsWithdrawal > 0) {
            savingsWithdrawStat = true
        } else {
            savingsWithdrawStat = false
        }


        const todayDate = new Date()

        const withdrawalTimeDiff = new Date(nextVestingWithdrawal).getTime() - todayDate.getTime()

        const withdrawalDayDiff = withdrawalTimeDiff / (1000 * 3600 * 24);

        let finalTimeInterval

        if (withdrawalDayDiff > 1.0) {
            finalTimeInterval = `${Math.round(withdrawalDayDiff)} days`
        }

        if (withdrawalDayDiff < 1) {
            finalTimeInterval = `${Math.round(withdrawalTimeDiff)} hrs`
        }

        // vesting withdrawal manager



        const rewardHive = getAccount[0].reward_hive_balance
        const rewardHBD = getAccount[0].reward_hbd_balance
        const rewardVesting = getAccount[0].reward_vesting_hive

        const walletData = {
            hiveBalance: parseFloat(getAccount[0].balance).toFixed(3),
            hbdBalance: parseFloat(getAccount[0].hbd_balance).toFixed(3),
            hbdSavings: parseFloat(getAccount[0].savings_hbd_balance).toFixed(3),
            hivePower: parseFloat(hivePower).toFixed(3),
            delegatedHivePower: parseFloat(delegatedHivePower).toFixed(3),
            estimatedAccountValue: parseFloat(estimatedAccountValue).toFixed(3),
            pendingVestWithdraw,
            pendingVestWithdrawStat,
            nextVestingWithdrawal,
            vestingWithdrawalRate,
            finalTimeInterval,
            vestingWithdrawalHive,
            savingsWithdrawStat,
            rewardHive,
            rewardHBD,
            rewardVesting
        }

        return walletData
    },
    claimRewards: async function (user) {
        
    },
    getUserSubscriptions: async function(user) {
        const subscriptionsRes = await axios.post(
            'https://api.hive.blog', 
            JSON.stringify({
                "jsonrpc":"2.0", "method":"bridge.list_all_subscriptions", "params":{"account": user}, "id":1
            })
        )

        return subscriptionsRes.data.result
    },
    getCommunity: async function(name) {
        const community = await axios.post(
            'https://api.hive.blog', 
            JSON.stringify({"jsonrpc":"2.0", "method":"bridge.get_community", "params":{"name":name}, "id":1})
        )

        return community
    },
    getUserFeed: async function(name) {
        const feed = await axios.post(
            'https://api.hive.blog', 
            JSON.stringify({"jsonrpc":"2.0", "method":"condenser_api.get_discussions_by_feed", "params":[{"tag":name,"limit":100}], "id":1})
        )

        return feed.data.result
    },
    getPopularCreators: async function(posts, user) {
        let popularCreatorsArr = []
        
        for (let i = 0; i <= posts.length; i++) {
            if (i === posts.length) {
                return popularCreatorsArr
            } else {
                popularCreatorsArr.push({
                    author: posts[i].author
                })
            }
        }
        return popularCreatorsArr;
    },
    checkLang: async function (t) {
        const titleLang = detect(t.title)
        const possibleTitleLangs = detectAll(t.title)
        return (titleLang === 'en' && possibleTitleLangs.length === 1)
    },
    getTrendingOp: async function (tag) { 
        const tryPosts = await axios.post(
            'https://api.hive.blog', 
            JSON.stringify({
                "jsonrpc":"2.0", "method":"condenser_api.get_discussions_by_trending", "params":[{
                    "tag": tag,
                    "limit": 20,
                  }], "id":1
            })
        )
        console.log('try posts trending', tryPosts.data.result)
        return tryPosts.data.result//.filter(this.checkLang);
    },
    getHotOp: async function (tag) {
        const tryPosts = await axios.post(
            'https://api.hive.blog', 
            JSON.stringify({
                "jsonrpc":"2.0", "method":"condenser_api.get_discussions_by_hot", "params":[{
                    "tag": tag,
                    "limit": 20,
                  }], "id":1
            })
        )
        console.log('try posts hot', tryPosts.data.result)
        return tryPosts.data.result//.filter(this.checkLang);
    },
    getNewOp: async function (tag) {
        const tryPosts = await axios.post(
            'https://api.hive.blog', 
            JSON.stringify({
                "jsonrpc":"2.0", "method":"condenser_api.get_discussions_by_created", "params":[{
                    "tag": tag,
                    "limit": 20,
                  }], "id":1
            })
        )
        console.log('try posts new', tryPosts.data.result)
        return tryPosts.data.result//.filter(this.checkLang);
    },

    getTrendingOpCustom: async function () {
        let trendingPosts = [];
        return new Promise ((resolve, reject) => {
            tagsList.forEach(async (tag, index) => {
                const tryPosts = await axios.post(
                    'https://api.hive.blog', 
                    JSON.stringify({
                        "jsonrpc":"2.0", "method":"condenser_api.get_discussions_by_trending", "params":[{
                            "tag": tag,
                            "limit": 100,
                        }], "id":1
                    })
                )

                let posts = [];

                if (tryPosts.data.result) {posts = tryPosts?.data?.result?.filter(post => {return post.active_votes.some(vote => vote.voter === 'stemsocial' && parseFloat(vote.percent) >= 3000)}) || []};

                if (posts) trendingPosts = trendingPosts.concat(posts);

                //console.log('posts 0', posts[0])

                if (index === tagsList.length - 1) {
                    resolve(trendingPosts)
                }
            })
        })

        
    },
    getHotOpCustom: async function () {
        let hotPosts = [];

        return new Promise ((resolve, reject) => {
            tagsList.forEach(async (tag, index) => {
                const tryPosts = await axios.post(
                    'https://api.hive.blog', 
                    JSON.stringify({
                        "jsonrpc":"2.0", "method":"condenser_api.get_discussions_by_hot", "params":[{
                            "tag": tag,
                            "limit": 40,
                        }], "id":1
                    })
                )

                let posts = [];

                if (tryPosts.data.result) {posts = tryPosts.data.result.filter(post => { return post.active_votes.some(vote => vote.voter === 'stemsocial')});}

                if (posts) hotPosts = hotPosts.concat(posts);

                if (index === tagsList.length - 1) {
                    resolve(hotPosts)
                }
            })
        })
    },
    getNewOpCustom: async function () {
        let newPosts = [];

        return new Promise ((resolve, reject) => {
            tagsList.forEach(async (tag, index) => {
                const tryPosts = await axios.post(
                    'https://api.hive.blog', 
                    JSON.stringify({
                        "jsonrpc":"2.0", "method":"condenser_api.get_discussions_by_created", "params":[{
                            "tag": tag,
                            "limit": 10,
                        }], "id":1
                    })
                )

                let posts = [];

                if (tryPosts.data.result) {posts = tryPosts.data.result/*.filter(post => { return post.active_votes.some(vote => vote.voter === 'stemsocial')});*/}

                if (posts) newPosts = newPosts.concat(posts);

                if (index === tagsList.length - 1) {
                    resolve(newPosts)
                }
            })
        })
    }
}

export default ops;