import { Layout, Row, Col } from 'antd';
import { GithubOutlined, TwitterOutlined, LinkedinOutlined } from '@ant-design/icons';
import footerImg from '../../assets/footerImg.jpg'

const { Footer } = Layout;

const MainFooter = () => {
  return (
    <Footer style={{ padding: 0 }}>
      <img
        src={footerImg}  // Replace with your actual image URL
        alt="Footer Image"
        style={{ width: '100%', height: 'auto', maxWidth: '100%' }}
      />
    </Footer>
  );
};

const SubFooter = () => {
  return (
    <Footer style={{ background: '#f0f2f5' }}>
      <Row justify="center" align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 20 }} md={{ span: 16 }} lg={{ span: 12 }} xl={{ span: 8 }}>
          <ul style={{ listStyle: 'none', padding: 0, textAlign: 'center' }}>
            <li style={{ display: 'inline-block', margin: '0 10px' }}>
              <a href="/about">
                About Us
              </a>
            </li>
            <li style={{ display: 'inline-block', margin: '0 10px' }}>
              <a href="/faq">
                FAQ
              </a>
            </li>
            <li style={{ display: 'inline-block', margin: '0 10px' }}>
              <a href="/tos">
                Terms Of Service
              </a>
            </li>
            <li style={{ display: 'inline-block', margin: '0 10px' }}>
              Supported By <a href="https://curiesteem.com" target="_blank" rel="noopener noreferrer">
                Curie
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Footer>
  );
};

const SubFooterTwo = () => {
  return (
    <Footer style={{ background: '#f0f2f5' }}>
      <Row justify="center" align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 20 }} md={{ span: 16 }} lg={{ span: 12 }} xl={{ span: 8 }}>
          <ul style={{ listStyle: 'none', padding: 0, textAlign: 'center' }}>
            <li style={{ display: 'inline-block', margin: '0 10px' }}>
              <a href="https://github.com" target="_blank" rel="noopener noreferrer">
                <GithubOutlined />
              </a>
            </li>
            <li style={{ display: 'inline-block', margin: '0 10px' }}>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <TwitterOutlined />
              </a>
            </li>
            <li style={{ display: 'inline-block', margin: '0 10px' }}>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                <LinkedinOutlined />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Footer>
  );
};

const AppFooter = () => {
  return (
    <>
      <MainFooter />
      <SubFooter />
      <SubFooterTwo />
    </>
  );
};

export default AppFooter;
