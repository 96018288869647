import React from "react";
import * as Icon from 'react-bootstrap-icons';
import MarkdownIt from 'markdown-it';
import TurndownService from 'turndown';
import Vote from './widgets/vote';
import Reblog from './widgets/reblog';
import Follow from './widgets/follow';
import Comment from './views/comment'
import ops from "../services/hiveOps"
import { Remarkable } from 'remarkable';
import sanitizeConfig, { noImageText } from '../utils/sanitizeConfig';
import sanitize from 'sanitize-html';
import HtmlReady from '../utils/HtmlReady';
import { generateMd as EmbeddedPlayerGenerateMd } from './widgets/EmbeddedPlayers';
import {extractImageLink} from '../utils/extractImageLink'
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import TimeAgo from 'timeago-react';
import ReplyLink from "./widgets/replyLink";
import CommentBox from "./widgets/commentBox";
/*import remarkableStripper from '../utils/RemarkableStripper'
import sanitize from 'sanitize-html';
import {htmlDecode} from '../utils/htmlDecode'*/
//import ReactMarkdown from 'react-markdown'
//import remarkGfm from 'remark-gfm'
//import Markdown from 'markdown-to-jsx';


const hive = require("@hiveio/hive-js")
let md = new MarkdownIt()
const turndownService = new TurndownService()

const remarkable = new Remarkable({
    html: true, // remarkable renders first then sanitize runs...
    breaks: true,
    linkify: false, // linkify is done locally
    typographer: false, // https://github.com/jonschlinkert/remarkable/issues/142#issuecomment-221546793
    quotes: '“”‘’',
});

const remarkableToSpec = new Remarkable({
    html: true,
    breaks: false, // real markdown uses \n\n for paragraph breaks
    linkify: false,
    typographer: false,
    quotes: '“”‘’',
});

// If you're in the browser, the Remarkable class is already available in the window
//var md = new Remarkable();

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

const author = getUrlParameter('author')
const permlink = getUrlParameter('permlink')
let loggedInUser = localStorage.getItem('username') ? localStorage.getItem('username') : null

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function Post() {
    

  const [post, setPost] = React.useState([]);
  const [followers, setFollowers] = React.useState([]);
  const [following, setFollowing] = React.useState([]);
  const [morePosts, setMorePosts] = React.useState([]);
  const [comments, setComments] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  let [color, setColor] = React.useState('#1b1c1d');
  let [followCount, setFollowCount] = React.useState(0);

  let morePostsInit = []
  let commentsPosts = []
  let allFollowerArr = []
  let allFollowingArr = []

  function adjustImageWidth() {
    // Get all the img elements on the page
    const imgElements = document.querySelectorAll('img');
  
    // Iterate through each img element
    imgElements.forEach((img) => {
      // Check if the img element doesn't have the class 'logo-img' and the id is not 'avatar'
      if (!img.classList.contains('logo-img') && img.id !== 'avatar') {
        // Set the width to 50% with !important
        img.style.setProperty('width', '60%', 'important');
        // Center the image horizontally and vertically
        img.style.display = 'block';
        img.style.margin = '0 auto';
        img.style.verticalAlign = 'middle';
      }
    });
  }

  React.useEffect(() => {
    let postObj = {
        cover: '',
        title: '',
        body: null,
        voted: false
    }
    async function getPost() {
        let post = await hive.api.getContentAsync(author, permlink);
        let postReplies = await hive.api.getContentRepliesAsync(author, permlink);
        
        async function sortComments(a) {
            await a.forEach(async d => {
                if (d.body.length > 0) {
                    let json = d.json_metadata
                    let voted = false
    
                    if (loggedInUser) {
                        d.active_votes.forEach(v => {
                            if (v.voter === loggedInUser) {
                                voted = true
                            }
                        })
                    }

                    //let commentReplies = await hive.api.getContentRepliesAsync(d.author, d.permlink);
                    
                    //console.log(commentReplies)

                    let postBody = d.body

                    let html = false;
                    // See also ReplyEditor isHtmlTest
                    const m = postBody.match(/^<html>([\S\s]*)<\/html>$/);
                    if (m && m.length === 2) {
                        html = true;
                        postBody = m[1];
                    } else {
                        // See also ReplyEditor isHtmlTest
                        html = /^<p>[\S\s]*<\/p>/.test(postBody);
                    }

                    // Strip out HTML comments. "JS-DOS" bug.
                    postBody = postBody.replace(/<!--([\s\S]+?)(-->|$)/g, '(html comment removed: $1)');

                    let renderer = remarkableToSpec;
                    /*if (this.props.breaks === true) {
                        renderer = remarkable;
                    }*/

                    let renderedText = html ? postBody : renderer.render(postBody);

                    // If content isn't wrapped with an html element at this point, add it.
                    if (!renderedText.indexOf('<html>') !== 0) {
                        renderedText = '<html>' + renderedText + '</html>';
                    }

                    let hideImages = false;

                    // Embed videos, link mentions and hashtags, etc...
                    if (renderedText) renderedText = HtmlReady(renderedText, { hideImages }).html;

                    let cleanText = renderedText;

                    let highQualityPost = post.payout > 10.0 ? true : false
                    let large = true

                    cleanText = sanitize(
                        renderedText,
                        sanitizeConfig({
                            large,
                            highQualityPost,
                            noImage: false && false,
                        })
                    );

                    if (/<\s*script/gi.test(cleanText)) {
                        // Not meant to be complete checking, just a secondary trap and red flag (code can change)
                        console.error('Refusing to render script tag in post text', cleanText);
                        return <div />;
                    }

                    const noImageActive = cleanText.indexOf(noImageText) !== -1;
                    
                    

                    // In addition to inserting the youtube component, this allows
                    // react to compare separately preventing excessive re-rendering.
                    let idx = 0;
                    let sections = [];

                    function checksum(s) {
                        let chk = 0x12345678;
                        const len = s.length;
                        for (let i = 0; i < len; i += 1) {
                            chk += s.charCodeAt(i) * (i + 1);
                        }

                        // eslint-disable-next-line no-bitwise
                        return (chk & 0xffffffff).toString(16);
                    }

                    // HtmlReady inserts ~~~ embed:${id} type ~~~
                    for (let section of cleanText.split('~~~ embed:')) {
                        const embedMd = EmbeddedPlayerGenerateMd(section, idx, large);
                        if (embedMd) {
                            const { section: newSection, markdown } = embedMd;
                            section = newSection;
                            sections.push(markdown);

                            if (section === '') {
                                // eslint-disable-next-line no-continue
                                continue;
                            }
                        }

                        sections.push(<div key={checksum(section)} dangerouslySetInnerHTML={{ __html: section }} />);

                        idx += 1;
                    }


                    let className = ''
                    const cn = 'Markdown'
                    + (className ? ` ${className}` : '')
                    + (html ? ' html' : '')
                    + (large ? '' : ' MarkdownViewer--small');

                    let allowNoImage = false;
                    let finalText = '';
                    sections.forEach(async sec => {
                        if (!sec.props.className || sec.props.className !== 'videoWrapper') {
                            finalText += sec.props.dangerouslySetInnerHTML.__html
                        }
                        if (sec.props.className === 'videoWrapper') {
                            console.log(
                                sec.props.className, sec.props.style.position, sec.props.style.width, sec.props.style.height, sec.props.style.paddingBottom
                            )
                            console.log(sec.props)
                            finalText += 
                                `<div className=${sec.props.className} style=${{position: sec.props.style.position, width: sec.props.style.width, height: sec.props.style.height, paddingBottom: sec.props.style.paddingBottom}}>
                                    <iframe src=${sec.props.children.props.src} height=${sec.props.children.props.height} title=${sec.props.children.props.title}></iframe>
                                </div>`
                        }
                    })
            
                    let summary = d.body.replace(/(^(\n|\r|\s)*)>([\s\S]*?).*\s*/g, '');
                    if (d.body.length > 100) {
                        summary = d.body.substring(0, 90)
                    }
                    
                    summary = summary
                    await commentsPosts.push({
                        permlink: d.permlink,
                        author: d.author,
                        url: d.url,
                        last_update: d.last_update,
                        body: finalText,
                        voted,
                        pendingPayout: d.pending_payout_value === '0.000 HBD' ? d.total_payout_value :  d.pending_payout_value,
                        parent_author: d.parent_author,
                        parent_permlink: d.parent_permlink
                    })
                }
            });
        }

        await sortComments(postReplies)

        if(post) {
            postObj.title = post.title
            postObj.author = author
            postObj.voted = false
            postObj.reblogged = false
            postObj.pendingPayout = post.pending_payout_value === '0.000 HBD' ? post.total_payout_value :  post.pending_payout_value
            postObj.permlink = post.permlink

            if (loggedInUser) {
                post.active_votes.forEach(v => {
                    if (v.voter === loggedInUser) {
                        postObj.voted = true
                    }
                })
            }

            let postBody =  post.body

            let html = false;
            // See also ReplyEditor isHtmlTest
            const m = postBody.match(/^<html>([\S\s]*)<\/html>$/);
            if (m && m.length === 2) {
                html = true;
                postBody = m[1];
            } else {
                // See also ReplyEditor isHtmlTest
                html = /^<p>[\S\s]*<\/p>/.test(postBody);
            }

            // Strip out HTML comments. "JS-DOS" bug.
            postBody = postBody.replace(/<!--([\s\S]+?)(-->|$)/g, '(html comment removed: $1)');

            let renderer = remarkableToSpec;
            /*if (this.props.breaks === true) {
                renderer = remarkable;
            }*/

            let renderedText = html ? postBody : renderer.render(postBody);

            // If content isn't wrapped with an html element at this point, add it.
            if (!renderedText.indexOf('<html>') !== 0) {
                renderedText = '<html>' + renderedText + '</html>';
            }

            let hideImages = false;

            // Embed videos, link mentions and hashtags, etc...
            if (renderedText) renderedText = HtmlReady(renderedText, { hideImages }).html;

            let cleanText = renderedText;

            let highQualityPost = post.payout > 10.0 ? true : false
            let large = true

            cleanText = sanitize(
                renderedText,
                sanitizeConfig({
                    large,
                    highQualityPost,
                    noImage: false && false,
                })
            );

            if (/<\s*script/gi.test(cleanText)) {
                // Not meant to be complete checking, just a secondary trap and red flag (code can change)
                console.error('Refusing to render script tag in post text', cleanText);
                return <div />;
            }

            const noImageActive = cleanText.indexOf(noImageText) !== -1;
            
            

            // In addition to inserting the youtube component, this allows
            // react to compare separately preventing excessive re-rendering.
            let idx = 0;
            let sections = [];

            function checksum(s) {
                let chk = 0x12345678;
                const len = s.length;
                for (let i = 0; i < len; i += 1) {
                    chk += s.charCodeAt(i) * (i + 1);
                }

                // eslint-disable-next-line no-bitwise
                return (chk & 0xffffffff).toString(16);
            }

            // HtmlReady inserts ~~~ embed:${id} type ~~~
            for (let section of cleanText.split('~~~ embed:')) {
                const embedMd = EmbeddedPlayerGenerateMd(section, idx, large);
                if (embedMd) {
                    const { section: newSection, markdown } = embedMd;
                    section = newSection;
                    sections.push(markdown);

                    if (section === '') {
                        // eslint-disable-next-line no-continue
                        continue;
                    }
                }

                sections.push(<div key={checksum(section)} dangerouslySetInnerHTML={{ __html: section }} />);

                idx += 1;
            }


            let className = ''
            const cn = 'Markdown'
            + (className ? ` ${className}` : '')
            + (html ? ' html' : '')
            + (large ? '' : ' MarkdownViewer--small');

            let allowNoImage = false;
            let finalText = '';
            sections.forEach(async sec => {
                if (!sec.props.className || sec.props.className !== 'videoWrapper') {
                    finalText += sec.props.dangerouslySetInnerHTML.__html
                }
                if (sec.props.className === 'videoWrapper') {
                    const windowWidth = window.innerWidth

                    console.log(typeof windowWidth)

                    const wrapperHeight = windowWidth < 768 ? '100%' : '360px';

                    finalText += 
                        `<div className=${sec.props.className} style="position: relative; width: 100%; height: fit-content;">
                            <iframe src=${sec.props.children.props.src} height=${wrapperHeight} width=100% title=${sec.props.children.props.title}></iframe>
                        </div>`
                }
            })

            postObj.body = finalText;


            let json = post.json_metadata
            if (json) {
                json = JSON.parse(json)
            }
            if (json.image) {
                if (json.image.length > 0) {
                    postObj.cover = json.image[json.image.length - 1]
                }
            }

            setPost(postObj)
        }

        if (commentsPosts) {
            setComments(commentsPosts)
        }
         
        setLoading(false);
        document.getElementById('hideOnLoad').style.display = 'none'
    }

    getPost()
  }, []);

  React.useEffect(() => {
    if (!loading && window.innerWidth > 768) {
        adjustImageWidth()
    }
  }, [loading]);

  function createMarkup(params) {
    return {__html: params};
  }

  let editLink = <></>

  if (loggedInUser !== null && loggedInUser === author) {
      editLink = <a href={`/edit?user=${author}&permlink=${permlink}`} style={{fontSize: '15px', display: 'inline-flex', cursor: 'pointer', marginLeft: '40% !important', width: '50%', color: '#1b1c1d', textDecoration: 'none'}}>Edit</a>
  }

  return (
      

    <div className="post-detail" id="post-page-content" style={{padding: '3em 2%'}}>
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12" key={post.permlink} data-author={post.author} id={`${post.author}/${post.permlink}`} style={{
                    padding: '2em 0'
                }}>
                {<div className="card post-card" style={{
                    //zIndex: '1',
                    border: '1px solid rgba(0,0,0,.125)',
                    borderRadius: '1em',
                    margin: 'auto'
                }}>
                    <div className="card-body row" style={{backgroundColor: "#fff !important", borderBottom: "none", padding: '1em 0 0 1em'}}>
                        <div className="text-start col-md-1">
                            <img id="avatar" src={`https://images.hive.blog/u/${post.author}/avatar`} style={{borderRadius: '50%'}} loading="lazy"  />
                        </div>
                        
                        <div className="text-start col-md-11">
                            <h5 className="card-title col" style={{fontSize: '1em', marginBottom: '0'}}><b>{post.title}</b></h5>
                            <p style={{marginBottom: '0'}}><small>by <a href="/" style={{color: '#000', textDecoration: 'none', marginBottom: '0', color: 'rgb(26, 34, 56)'}}><b>{post.author}</b></a></small></p>
                        </div>
                        
                    </div>
                    <hr />
                    <div id="hideOnLoad" className="card-body post-card-body">
                        
                        <div className="sweet-loading text-center" style={{marginTop: '15%'}}>
                            <PulseLoader color={color} loading={loading} css={override} size={50} />
                        </div>
                    </div>
                    <div className="card-body post-card-body">
                        <div className="card-text text-start" id="post-body-text" dangerouslySetInnerHTML={createMarkup(post.body)} />
                        <div className="card-text text-start">
                            <hr />
                            <span style={{display: 'inline-flex', textDecoration: 'none'}}>
                                {editLink}
                            </span>
                            <ReplyLink props={{
                                id: permlink + author,
                                parent_author: author,
                                parent_permlink: permlink,
                                isComment: false,
                                title: post.title
                            }} />
                            <hr />
                        </div>
                    </div>
                    
                    <div className="card-footer post-footer-area row" style={{backgroundColor: "#1b1c1d", display: 'flex'}}>
                        <span className="vote-post text-white col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '35%', textAlign: 'center'}}>
                            <Vote props={{
                                author,
                                permlink,
                                voted: post.voted,
                                voter: loggedInUser
                            }} />
                        </span>
                        {/*<span className="post-comments text-white col" style={{  width: '20%' }}>
                            <a href={`/post?permlink=${post.permlink}&author=${post.author}`} style={{cursor: 'pointer !important'}} className="text-white">
                                < Icon.Chat />
                            </a>
                        </span>*/}
                        <span className="reblog-post text-white col" style={{  width: '20%' }}>
                            <Reblog props={{
                                author: post.author,
                                permlink: post.permlink,
                                reblogged: post.reblogged,
                                username: loggedInUser
                            }} />
                        </span>
                        <span className="pending-payout text-white col" style={{fontSize: '0.7em', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '25%'}}>
                            <b>{post.pendingPayout}</b>
                        </span>
                    </div>
                </div>}
            </div>
            <hr />

            <h5>Comments</h5>
            <div style={{
                width: '80%',
                margin: 'auto'
            }}>
                <Comment props={{
                    comments,
                    title: post.title
                }} />
            </div>
            <br />
            <br />
        </div>
    </div>
  );
}

export default Post;