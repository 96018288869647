import React from "react";
import { Routes, Route, useMatch } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import MarkdownIt from 'markdown-it';
import TurndownService from 'turndown';
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import Vote from './widgets/vote'
import Reblog from './widgets/reblog';
import Follow from './widgets/follow';
import ops from "../services/hiveOps"
import TimeAgo from 'timeago-react';
import {keychain, isKeychainInstalled, hasKeychainBeenUsed} from '@hiveio/keychain'
import Select from "react-dropdown-select";
import Modal from 'react-modal';
import { notification } from 'antd';

import ProfileHeader from "./widgets/profileHeader";

import styled from 'styled-components';

const DropDownContainer = styled("div")`
  float: right;
  width: 50%;
  margin: 0;
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  font-weight: 500;
  color: #1b1c1d;
  background: #ffffff;
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #1b1c1d;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  padding: 2%;
  text-align: left;
  cursor: pointer;
`;



const hive = require("@hiveio/hive-js")
let md = new MarkdownIt()
const turndownService = new TurndownService()

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

//let inscrybmde = new InscrybMDE()


const profile = getUrlParameter('user')
const screenWidth = window.screen.width + 'px'
let loggedInUser = localStorage.getItem('username') ? localStorage.getItem('username') : null

const customStyles = {
    content: {
      top: '15%',
      left: '20%',
      right: '20%',
      bottom: '20%',
      height: 'fit-content',
    },
};

Modal.setAppElement('#root');

function Wallet() {
    const [walletData, setWalletData] = React.useState([]);
    let [loading, setLoading] = React.useState(true);
    let [color, setColor] = React.useState('#1b1c1d');
    let [followStatus, setFollowStatus] = React.useState(false);
    let [followCount, setFollowCount] = React.useState(0);
    let [coverImage, setCoverImage] = React.useState('/img/default_avatar_2.png')
    let [hiveSelectOpen, setHiveSelectOpen] = React.useState(false) 
    let [hpSelectOpen, setHPSelectOpen] = React.useState(false) 
    let [hbdSelectOpen, setHBDSelectOpen] = React.useState(false) 
    let [savingSelectOpen, setSavingSelectOpen] = React.useState(false)
    let [hiveTrfModalIsOpen, setIsHiveTrfOpen] = React.useState(false);
    let [hbdTrfModalIsOpen, setIsHbdTrfOpen] = React.useState(false);
    let [hbdTrfToSavingsModalIsOpen, setIsHbdTrfToSavingsOpen] = React.useState(false);
    let [hbdTrfFromSavingsModalIsOpen, setIsHbdTrfFromSavingsOpen] = React.useState(false);
    let [hiveVestModalIsOpen, setIsHiveVestOpen] = React.useState(false);
    let [hpDelegateModalIsOpen, setIsHpDelegateOpen] = React.useState(false);
    let [hpVestDownModalIsOpen, setIsHpVestDownOpen] = React.useState(false);

    function openHiveTrfModal() {
        setIsHiveTrfOpen(true);
    }
    
    function closeHiveTrfModal() {
        setIsHiveTrfOpen(false);
    }

    function openHbdTrfModal() {
        setIsHbdTrfOpen(true);
    }
    
    function closeHbdTrfModal() {
        setIsHbdTrfOpen(false);
    }

    function openHiveVestModal() {
        setIsHiveVestOpen(true);
    }
    
    function closeHiveVestModal() {
        setIsHiveVestOpen(false);
    }

    async function openHpDelegateModal() {
        setIsHpDelegateOpen(true)
    }

    async function closeHpDelegateModal() {
        setIsHpDelegateOpen(false)
    }

    async function openHpVestDownModal() {
        setIsHpVestDownOpen(true)
    }

    async function closeHpVestDownModal() {
        setIsHpVestDownOpen(false)
    }

    async function openHbdTrfToSavingsModal () {
        setIsHbdTrfToSavingsOpen(true)
    }

    async function closeHbdTrfToSavingsModal () {
        setIsHbdTrfToSavingsOpen(false)
    }

    async function openHbdTrfFromSavingsModal () {
        setIsHbdTrfFromSavingsOpen(true)
    }

    async function closeHbdTrfFromSavingsModal () {
        setIsHbdTrfFromSavingsOpen(false)
    }


    const toggling = () => setHiveSelectOpen(!hiveSelectOpen);

    let refPosts = []
    let authorPosts = []
    let postImgHeight = '250px'
    let wifInputDisplay = 'none';

    if (localStorage.getItem('keychain') !== 'yes') {
        wifInputDisplay = 'block'
    }

    

    React.useEffect(() => {

        async function getWalletData() {

            const getWalletData = await ops.getWalletData(profile);

            setWalletData(getWalletData)
            
            const [account] = await hive.api.getAccountsAsync([profile]);

            let cover = ''; 

            let json = {}

            if (account.posting_json_metadata || account.json_metadata) {
                json = JSON.parse(account.posting_json_metadata || account.json_metadata)
            }

            if (json.profile && json.profile.cover_image !== undefined) {
                cover = json.profile.cover_image;
            }

            if (cover.length > 0) {
                setCoverImage(cover)
            }

            document.getElementById('hideOnLoad').style.display = 'none'

            async function checkFollowing(follower, user) {
                let checkFollowOp = await ops.checkFollowing(follower, user);
                return checkFollowOp;
            }

            async function getFollowCount(user) {
                let getCount = await ops.getFollowCount(user);
                return getCount;
            }

            const followCountOp = await getFollowCount(profile)

            setFollowCount(followCountOp.follower_count)

            const followStatus = await checkFollowing(loggedInUser, profile)

            setFollowStatus(followStatus)
        }

        getWalletData()
    }, []);

    let followDisplay = 'block'

    if (loggedInUser === profile) {
        followDisplay = 'none'
    }
    

    function createMarkup(params) {
        return {__html: params};
    }

    async function claimRewards(user) {  
        notification.open({
            //message: 'Notification Title',
            description:
            'Claiming',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });      
        let [account] = await hive.api.getAccountsAsync([user]);
        let rewardHive = account.reward_hive_balance
        let rewardHBD = account.reward_hbd_balance
        let rewardHP = account.reward_vesting_balance
        let hivePower = document.getElementById('user-hp').innerText

        const vestingShares = account.vesting_shares;
    
        const vestPercentage = (parseFloat(rewardHP) / parseFloat(hivePower)) * 100
    
        const rewardVests = (vestPercentage / 100) * parseFloat(vestingShares)

        console.log(rewardHive, rewardHP, rewardHBD)



        const keychainStatus = localStorage.getItem('keychain')

        
        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', user, 5,  'test memo', 'HIVE')

        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {
                let operation = [
                    "claim_reward_balance",
                    {
                        "account": user,
                        "reward_hive": rewardHive,
                        "reward_hbd": rewardHBD,
                        "reward_vests":rewardHP
                    }
                ]

                //const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', voter, [operation], 'Posting');

                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', user, [operation], 'Active');

                if (success) {
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Successful',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    return {success: 'yes'}
                }
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = localStorage.getItem('ajbhs')
                    const sendPost = await hive.broadcast.claimRewardBalanceAsync(dybnjg, user, rewardHive, rewardHBD, rewardHP)
                    if (sendPost.id) {
                        notification.open({
                            //message: 'Notification Title',
                            description:
                            'Rewards claimed successfully',
                            onClick: () => {
                            console.log('Notification Clicked!');
                            },
                        });
                    }
                } catch (error) {
                    console.log(error)
                    notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });                
                }
            }
        }
    }
    
    if (walletData.vestingWithdrawalHive <= 0.000) {
        document.getElementById('power-down-notifier').style.display = 'none'
    }


    let rewardsNotifierDisp = 'inline-flex'
    if (loggedInUser !== profile) {
        rewardsNotifierDisp = 'none'
    }

    async function sendHive() {
        notification.open({
            //message: 'Notification Title',
            description:
            'Sending',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
        const from = document.getElementById('hive-sender').value;
        const to = document.getElementById('hive-to').value;
        const amount = document.getElementById('hive-amount').value;
        const memo = document.getElementById('hive-memo').value;

        console.log(from, to, memo, amount)

        

        const keychainStatus = localStorage.getItem('keychain')

        
        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', profile, 5,  'test memo', 'HIVE')

        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {

                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', profile, to, amount,  memo, 'HIVE');

                if (success) {
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Transfer successful',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    return {success: 'yes'}
                }
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = document.getElementById('hive-whjbs').value
                    const sendHive = await hive.broadcast.transferAsync(dybnjg, from, to, `${parseFloat(amount).toFixed(3)} HIVE`, memo);
                    
                    if (sendHive.id) {
                        notification.open({
                            //message: 'Notification Title',
                            description:
                            'Transfer successful',
                            onClick: () => {
                            console.log('Notification Clicked!');
                            },
                        });
                        
        
                    }
                } catch (error) {
                    console.log(error)
                    notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });                
                }
            }
        }
    }

    async function powerUpHive() {
        notification.open({
            //message: 'Notification Title',
            description:
            'Powering up',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
        const from = document.getElementById('hp-sender').value;
        const to = document.getElementById('hp-to').value;
        const amount = document.getElementById('hp-amount').value;
        const memo = document.getElementById('hp-memo').value;

        console.log(from, to, memo, amount)

        

        const keychainStatus = localStorage.getItem('keychain')

        
        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', profile, 5,  'test memo', 'HIVE')

        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {
                let operation = ['transfer_to_vesting', {
                    amount: `${parseFloat(amount).toFixed(3)} HIVE`,
                    from: from,
                    to: to
                }]

                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', profile, [operation], 'Active');

                if (success) {
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Power up successful',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    return {success: 'yes'}
                }
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = document.getElementById('hive-whjbs').value
                    const sendPup = await hive.broadcast.transferToVestingAsync(dybnjg, from, to, `${parseFloat(amount).toFixed(3)} HIVE`);
                      
                    if (sendPup.id) {
                        notification.open({
                            //message: 'Notification Title',
                            description:
                            'Power up successful',
                            onClick: () => {
                            console.log('Notification Clicked!');
                            },
                        });
                    }
                } catch (error) {
                    console.log(error)
                    notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });                
                }
            }
        }
    }

    async function delegateHp() {
        notification.open({
            //message: 'Notification Title',
            description:
            'Delegating',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
        const from = document.getElementById('hp-delegator').value;
        const to = document.getElementById('delegation-to').value;
        const amount = document.getElementById('delegation-amount').value;
        let hivePower = document.getElementById('user-hp').innerText

        const getAccount = await hive.api.getAccountsAsync([profile]);

        const vestingShares = getAccount["0"].vesting_shares;

        const vestPercentage = (parseFloat(amount) / parseFloat(hivePower)) * 100

        const getDelegateVest = (vestPercentage / 100) * parseFloat(vestingShares)

        const keychainStatus = localStorage.getItem('keychain')

        
        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', profile, 5,  'test memo', 'HIVE')

        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {
                let operation = ["delegate_vesting_shares",
                {
                    "delegator": from,
                    "delegatee": to,
                    "vesting_shares": getDelegateVest.toFixed(6) + ' VESTS'
                }]

                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', profile, [operation], 'Active');

                if (success) {
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Delegation successful',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    return {success: 'yes'}
                }
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
                
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = document.getElementById('hive-whjbs').value
                    const sendDel = await hive.broadcast.delegateVestingSharesAsync(dybnjg, from, to, getDelegateVest.toFixed(6) + ' VESTS')
                      
                    if (sendDel.id) {
                        notification.open({
                            //message: 'Notification Title',
                            description:
                            'Successful',
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                    }
                } catch (error) {
                    console.log(error)
                    notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });                
                }
            }
        }
    }

    async function powerDown() {
        notification.open({
            //message: 'Notification Title',
            description:
            'Powering down',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
        const from = document.getElementById('hp-account').value;
        const amount = document.getElementById('vest-down-amount').value;
        let hivePower = document.getElementById('user-hp').innerText

        const getAccount = await hive.api.getAccountsAsync([profile]);

        const vestingShares = getAccount["0"].vesting_shares;

        const vestPercentage = (parseFloat(amount) / parseFloat(hivePower)) * 100

        const getPowerDownVest = (vestPercentage / 100) * parseFloat(vestingShares)

        const keychainStatus = localStorage.getItem('keychain')

        
        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', profile, 5,  'test memo', 'HIVE')

        let operation = ['withdraw_vesting', {
            account: from,
            vesting_shares: getPowerDownVest.toFixed(6) + ' VESTS'
        }]
        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {

                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', profile, [operation], 'Active');

                if (success) {
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Successful',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    return {success: 'yes'}
                }
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = document.getElementById('hive-whjbs').value
                    const sendPost = await hive.broadcast.sendAsync({ operations: [operation] }, { active: dybnjg });
                    if (sendPost.id) {
                        notification.open({
                            //message: 'Notification Title',
                            description:
                            'Successful',
                            onClick: () => {
                                console.log('Notification Clicked!');
                            }
                        });
                    }
                } catch (error) {
                    console.log(error)
                    notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });                
                }
            }
        }
    }

    async function cancelPowerDown() {
        await setIsHpVestDownOpen(true)

        document.getElementById('vest-down-amount').value = 0.000

        document.getElementById('vest-down-amount').setAttribute('readonly', 'readonly')
    }

    async function sendHBD() {
        notification.open({
            //message: 'Notification Title',
            description:
            'Sending',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
        const from = document.getElementById('hbd-sender').value;
        const to = document.getElementById('hbd-to').value;
        const amount = document.getElementById('hbd-amount').value;
        const memo = document.getElementById('hbd-memo').value;

        console.log(from, to, memo, amount)

        

        const keychainStatus = localStorage.getItem('keychain')

        
        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', profile, 5,  'test memo', 'HIVE')

        let operation = [
            "transfer",
            {
                "from": from,
                "to": to,
                "amount": parseFloat(amount).toFixed(3) + " HBD",
                "memo": memo
            }
        ]
        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {

                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', profile, [operation], 'Active');

                if (success) {
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Successful',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    return {success: 'yes'}
                }
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = document.getElementById('hive-whjbs').value
                    const sendPost = await hive.broadcast.sendAsync({ operations: [operation] }, { active: dybnjg });
                    if (sendPost.id) {
                        notification.open({
                            //message: 'Notification Title',
                            description:
                            'Successful',
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                    }
                } catch (error) {
                    console.log(error)
                    notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });                
                }
            }
        }
    }

    async function sendHbdToSavings() {
        notification.open({
            //message: 'Notification Title',
            description:
            'Sending',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
        const from = document.getElementById('hbd-saving-sender').value;
        const amount = document.getElementById('hbd-saving-amount').value;

        console.log(from, amount)

        

        const keychainStatus = localStorage.getItem('keychain')

        let operation = [
            "transfer_to_savings",
            {
              "from": from,
              "to": from,
              "amount": parseFloat(amount).toFixed(3) + " HBD",
              "memo": ''
            }
        ]
        
        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', profile, 5,  'test memo', 'HIVE')

        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {

                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', profile, [operation], 'Active');

                if (success) {
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Successful',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    return {success: 'yes'}
                }
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = document.getElementById('hive-whjbs').value
                    const sendPost = await hive.broadcast.sendAsync({ operations: [operation] }, { active: dybnjg });
                    if (sendPost.id) {
                        notification.open({
                            //message: 'Notification Title',
                            description:
                            'Successful',
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                    }
                } catch (error) {
                    console.log(error)
                    notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });                
                }
            }
        }
    }

    async function sendHbdFromSavings() {
        notification.open({
            //message: 'Notification Title',
            description:
            'Sending',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
        const from = document.getElementById('hbd-withdrawer').value;
        const amount = document.getElementById('hbd-withdraw-amount').value;

        console.log(from, amount)        

        const keychainStatus = localStorage.getItem('keychain')

        let operation = ["transfer_from_savings", {
            "from": from,
            "request_id": 101,
            "to": from,
            "amount": parseFloat(amount).toFixed(3) + " HBD",
            "memo": ""
        }]
        
        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', profile, 5,  'test memo', 'HIVE')

        if(isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {

                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', profile, [operation], 'Active');

                if (success) {
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Successful',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    return {success: 'yes'}
                }
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
                
            } else {
                try {
                    const dybnjg = document.getElementById('hive-whjbs').value
                    const sendPost = await hive.broadcast.sendAsync({ operations: [operation] }, { active: dybnjg });
                    if (sendPost.id) {
                        notification.open({
                    //message: 'Notification Title',
                    description:
                    'Successful',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
                    }
                } catch (error) {
                    console.log(error)
                    notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });                
                }
            }
        }
    }

    return (
        <div className="posts" id="page-content">
            <div className="row trending-posts">
                <ProfileHeader props={{
                    followStatus,
                    followCount,
                    coverImage,
                    followDisplay
                }} />
                <div className="sweet-loading col-lg-12 col-md-12 col-sm-12" id="hideOnLoad" style={{marginTop: '5%'}}>
                    <PulseLoader color={color} loading={loading} css={override} size={50} />
                </div>

                <div className="wallet-area" style={{padding: '3% 10%', fontSize: '12px'}}>
                    <div className="row">
                        <div className="card col-lg-12 col-md-12 col-sm-12 wallet-info-area">
                            <div className="card-body user-info-sec">
                                <h6 const="user-info-area">@<span className="username-text">{profile}</span>'s Wallet</h6>
                            </div>
                            <div className="card-body" id="power-down-notifier">
                                <p>Next power down amount, approximately {walletData.vestingWithdrawalHive} HIVE</p>
                            </div>
                            <div className="card-body row" id="rewards-notifier" style={{display: rewardsNotifierDisp}}>
                                <div className="col-8">
                                    <p><span id="reward-hive">{ walletData.rewardHive }</span>, <span id="reward-hbd">{ walletData.rewardHBD }</span>, <span id="reward-hp">{ walletData.rewardVesting }</span></p>
                                </div>
                                <div className="col-4" id="claimer-btn">
                                    <center>
                                        <button type="button" className="btn claim-rewards" style={{backgroundColor: '#1b1c1d', color: 'white'}} onClick={() => {
                                            claimRewards(loggedInUser)
                                        }}>Claim</button>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="card col-lg-12 col-md-12 col-sm-12 token-area">
                            <div className="card-body">
                                <div className="row container">
                                    <div className="col-lg-8 col-md-8 col-sm-12 text-left">
                                        <h5 className="text-left">Hive(Liquid)</h5>
                                        <p className="text-left">Liquid tokens that can be converted to Hive Power(HP) by powering up, or to other cryptocurrencies and FIAT by trading on HIVE listed exchanges</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-right currency-area">
                                        <DropDownContainer>
                                            <DropDownHeader style={{cursor: 'pointer'}} onClick={() => {
                                                if (loggedInUser === profile) {
                                                    setHiveSelectOpen(!hiveSelectOpen)
                                                } else {
                                                    notification.open({
                                                        //message: 'Notification Title',
                                                        description:
                                                        'You must be logged in to this account to perform operations',
                                                        onClick: () => {
                                                        console.log('Notification Clicked!');
                                                        },
                                                    });
                                                    
                                                }
                                            }}>{ walletData.hiveBalance } HIVE <Icon.ChevronDown /></DropDownHeader>
                                            {//hiveSelectOpen && (
                                                <DropDownListContainer style={{display: hiveSelectOpen === true ? 'block' : 'none'}}>
                                                    <DropDownList>
                                                        <ListItem onClick={openHiveTrfModal}>Transfer</ListItem>
                                                        <ListItem onClick={openHiveVestModal}>Power Up</ListItem>
                                                    </DropDownList>
                                                </DropDownListContainer>
                                            //)
                                            } 
                                        </DropDownContainer> 
                                        
                                        <div style={customStyles}>
                                            <Modal
                                                isOpen={hiveTrfModalIsOpen}
                                                onRequestClose={closeHiveTrfModal}
                                                style={customStyles}
                                                contentLabel="Example Modal"
                                            >
                                                <div>
                                                    <span className="text-end"> <i style={{cursor: 'pointer', color: '#1b1c1d', backgroundColor: 'white'}} onClick={closeHiveTrfModal}><Icon.XCircle /></i></span>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <h6><b>Send Hive</b></h6>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hive-sender">From</label>
                                                        <input type="text" id="hive-sender" name="hive-sender-input" style={{width: '100%', padding: '1%'}} value={profile} disabled />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hive-to">To</label>
                                                        <input type="text" id="hive-to" name="hive-to-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hive-amount">Amount</label>
                                                        <input type="number" id="hive-amount" name="hive-amount-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hive-memo">Memo</label>
                                                        <input type="text" id="hive-memo" name="hive-memo-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '2%', display: wifInputDisplay}}>
                                                        <label htmlFor="hive-whjbs">Active Key</label>
                                                        <input type="password" id="hive-whjbs" name="hive-whjbs-input" style={{width: '100%', padding: '1%'}} />
                                                        <small>Please note that we do not store your active  key at any point during or after this transaction</small>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <a className="btn btn-sm text-end" style={{backgroundColor: '#1b1c1d', color: 'white', cursor: 'pointer !important'}}  onClick={() => {
                                                            sendHive()
                                                        }}>Send</a>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>

                                        <div style={customStyles}>
                                            <Modal
                                                isOpen={hiveVestModalIsOpen}
                                                onRequestClose={closeHiveVestModal}
                                                style={customStyles}
                                                contentLabel="Example Modal"
                                            >
                                                <div>
                                                    <span className="text-end"> <i style={{cursor: 'pointer', color: '#1b1c1d', backgroundColor: 'white'}} onClick={closeHiveVestModal}><Icon.XCircle /></i></span>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <h6><b>Power up Hive</b></h6>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hp-sender">From</label>
                                                        <input type="text" id="hp-sender" name="hp-sender-input" style={{width: '100%', padding: '1%'}} value={profile} disabled />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hp-to">To</label>
                                                        <input type="text" id="hp-to" name="hp-to-input" style={{width: '100%', padding: '1%'}} value={profile} disabled />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hp-amount">Amount</label>
                                                        <input type="number" id="hp-amount" name="hp-amount-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hp-memo">Memo</label>
                                                        <input type="text" id="hp-memo" name="hp-memo-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '2%', display: wifInputDisplay}}>
                                                        <label htmlFor="hive-whjbs">Active Key</label>
                                                        <input type="password" id="hive-whjbs" name="hive-whjbs-input" style={{width: '100%', padding: '1%'}} />
                                                        <small>Please note that we do not store your active  key at any point during or after this transaction</small>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <a className="btn btn-sm text-end" style={{backgroundColor: '#1b1c1d', color: 'white', cursor: 'pointer !important'}}  onClick={() => {
                                                            powerUpHive()
                                                        }}>Power up</a>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="card-body">
                                <div className="row container">
                                    <div className="col-lg-8 col-md-8 col-sm-12 text-left">
                                        <h5 className="text-left">Hive Power(HP)(Staked)</h5>
                                        <p className="text-left">Hive power is accumulated as rewards from curation and content creation. <br /> Users with more Hive Power will earn more Hive, so endeavor to power up some Hive as much as you can to increase your potential rewards</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-right currency-area">
                                        <DropDownContainer>
                                            <DropDownHeader style={{cursor: 'pointer'}} onClick={() => {
                                                if (loggedInUser === profile) {
                                                    setHPSelectOpen(!hpSelectOpen)
                                                } else {
                                                    notification.open({
                                                        //message: 'Notification Title',
                                                        description:
                                                        'You must be logged in to this account to perform operations',
                                                        onClick: () => {
                                                        console.log('Notification Clicked!');
                                                        },
                                                    });
                                                }
                                            }}><span id="user-hp">{ walletData.hivePower }</span> HP<br /><i>({ walletData.delegatedHivePower } HP)</i> <Icon.ChevronDown /></DropDownHeader>
                                            {//hiveSelectOpen && (
                                                <DropDownListContainer style={{display: hpSelectOpen === true ? 'block' : 'none'}}>
                                                    <DropDownList>
                                                        <ListItem onClick={openHpDelegateModal}>Delegate</ListItem>
                                                        <ListItem onClick={openHpVestDownModal}>Power Down</ListItem>
                                                        <ListItem onClick={cancelPowerDown}>Cancel Power Down</ListItem>
                                                    </DropDownList>
                                                </DropDownListContainer>
                                            //)
                                            } 
                                        </DropDownContainer>
                                        
                                        <div style={customStyles}>
                                            <Modal
                                                isOpen={hpDelegateModalIsOpen}
                                                onRequestClose={closeHpDelegateModal}
                                                style={customStyles}
                                                contentLabel="Example Modal"
                                            >
                                                <div>
                                                    <span className="text-end"> <i style={{cursor: 'pointer', color: '#1b1c1d', backgroundColor: 'white'}} onClick={closeHpDelegateModal}><Icon.XCircle /></i></span>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <h6><b>Delegate Hive</b></h6>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hp-delegator">From</label>
                                                        <input type="text" id="hp-delegator" name="hp-delegator-input" style={{width: '100%', padding: '1%'}} value={profile} disabled />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="deldgation-to">To</label>
                                                        <input type="text" id="delegation-to" name="delegation-to-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="delegation-amount">Amount</label>
                                                        <input type="number" id="delegation-amount" name="delegation-amount-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '2%', display: wifInputDisplay}}>
                                                        <label htmlFor="hive-whjbs">Active Key</label>
                                                        <input type="password" id="hive-whjbs" name="hive-whjbs-input" style={{width: '100%', padding: '1%'}} />
                                                        <small>Please note that we do not store your active  key at any point during or after this transaction</small>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <a className="btn btn-sm text-end" style={{backgroundColor: '#1b1c1d', color: 'white', cursor: 'pointer !important'}}  onClick={() => {
                                                            delegateHp()
                                                        }}>Delegate</a>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>
                                        
                                        <div style={customStyles}>
                                            <Modal
                                                isOpen={hpVestDownModalIsOpen}
                                                onRequestClose={closeHpVestDownModal}
                                                style={customStyles}
                                                contentLabel="Example Modal"
                                            >
                                                <div>
                                                    <span className="text-end"> <i style={{cursor: 'pointer', color: '#1b1c1d', backgroundColor: 'white'}} onClick={closeHpVestDownModal}><Icon.XCircle /></i></span>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <h6><b>Power Down</b></h6>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hp-account">From</label>
                                                        <input type="text" id="hp-account" name="hp-account-input" style={{width: '100%', padding: '1%'}} value={profile} disabled />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="vest-down-amount">Amount</label>
                                                        <input type="number" id="vest-down-amount" name="vest-down-amount-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '2%', display: wifInputDisplay}}>
                                                        <label htmlFor="hive-whjbs">Active Key</label>
                                                        <input type="password" id="hive-whjbs" name="hive-whjbs-input" style={{width: '100%', padding: '1%'}} />
                                                        <small>Please note that we do not store your active  key at any point during or after this transaction</small>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <a className="btn btn-sm text-end" style={{backgroundColor: '#1b1c1d', color: 'white', cursor: 'pointer !important'}}  onClick={() => {
                                                            powerDown()
                                                        }}>Power Down</a>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="card-body">
                                <div className="row container">
                                    <div className="col-lg-8 col-md-8 col-sm-12 text-left">
                                        <h5 className="text-left">Hive Backed Dollars(HBD)</h5>
                                        <p className="text-left">Can be converted to liquid HIVE</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-right">
                                        <DropDownContainer>
                                            <DropDownHeader style={{cursor: 'pointer'}} onClick={() => {
                                                if (loggedInUser === profile) {
                                                    setHBDSelectOpen(!hbdSelectOpen)
                                                } else {
                                                    notification.open({
                                                        //message: 'Notification Title',
                                                        description:
                                                        'You must be logged in to this account to perform operations',
                                                        onClick: () => {
                                                        console.log('Notification Clicked!');
                                                        },
                                                    });
                                                }
                                            }}>{ walletData.hbdBalance } HBD <Icon.ChevronDown /></DropDownHeader>
                                            {//hiveSelectOpen && (
                                                <DropDownListContainer style={{display: hbdSelectOpen === true ? 'block' : 'none'}}>
                                                    <DropDownList>
                                                        <ListItem onClick={openHbdTrfModal}>Transfer</ListItem>
                                                        <ListItem onClick={openHbdTrfToSavingsModal}>Transfer To Savings</ListItem>
                                                    </DropDownList>
                                                </DropDownListContainer>
                                            //)
                                            } 
                                        </DropDownContainer>

                                        <div style={customStyles}>
                                            <Modal
                                                isOpen={hbdTrfModalIsOpen}
                                                onRequestClose={closeHbdTrfModal}
                                                style={customStyles}
                                                contentLabel="Example Modal"
                                            >
                                                <div>
                                                    <span className="text-end"> <i style={{cursor: 'pointer', color: '#1b1c1d', backgroundColor: 'white'}} onClick={closeHbdTrfModal}><Icon.XCircle /></i></span>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <h6><b>Send HBD</b></h6>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hbd-sender">From</label>
                                                        <input type="text" id="hbd-sender" name="hbd-sender-input" style={{width: '100%', padding: '1%'}} value={profile} disabled />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hbd-to">To</label>
                                                        <input type="text" id="hbd-to" name="hbd-to-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hbd-amount">Amount</label>
                                                        <input type="number" id="hbd-amount" name="hbd-amount-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hbd-memo">Memo</label>
                                                        <input type="text" id="hbd-memo" name="hbd-memo-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '2%', display: wifInputDisplay}}>
                                                        <label htmlFor="hive-whjbs">Active Key</label>
                                                        <input type="password" id="hive-whjbs" name="hive-whjbs-input" style={{width: '100%', padding: '1%'}} />
                                                        <small>Please note that we do not store your active  key at any point during or after this transaction</small>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <a className="btn btn-sm text-end" style={{backgroundColor: '#1b1c1d', color: 'white', cursor: 'pointer !important'}}  onClick={() => {
                                                            sendHBD()
                                                        }}>Send</a>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>

                                        <div style={customStyles}>
                                            <Modal
                                                isOpen={hbdTrfToSavingsModalIsOpen}
                                                onRequestClose={closeHbdTrfToSavingsModal}
                                                style={customStyles}
                                                contentLabel="Example Modal"
                                            >
                                                <div>
                                                    <span className="text-end"> <i style={{cursor: 'pointer', color: '#1b1c1d', backgroundColor: 'white'}} onClick={closeHbdTrfToSavingsModal}><Icon.XCircle /></i></span>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <h6><b>Send HBD To Savings</b></h6>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hbd-saving-sender">From</label>
                                                        <input type="text" id="hbd-saving-sender" name="hbd-saving-sender-input" style={{width: '100%', padding: '1%'}} value={profile} disabled />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hbd-saving-amount">Amount</label>
                                                        <input type="number" id="hbd-saving-amount" name="hbd-saving-amount-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '2%', display: wifInputDisplay}}>
                                                        <label htmlFor="hive-whjbs">Active Key</label>
                                                        <input type="password" id="hive-whjbs" name="hive-whjbs-input" style={{width: '100%', padding: '1%'}} />
                                                        <small>Please note that we do not store your active  key at any point during or after this transaction</small>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <a className="btn btn-sm text-end" style={{backgroundColor: '#1b1c1d', color: 'white', cursor: 'pointer !important'}}  onClick={() => {
                                                            sendHbdToSavings()
                                                        }}>Send</a>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="card-body">
                                <div className="row container">
                                    <div className="col-lg-8 col-md-8 col-sm-12 text-left">
                                        <h5 className="text-left">Hive Savings</h5>
                                        <p className="text-left">Save your HIVE tokens</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-right">
                                        <DropDownContainer>
                                            <DropDownHeader style={{cursor: 'pointer'}} onClick={() => {
                                                if (loggedInUser === profile) {
                                                    setSavingSelectOpen(!savingSelectOpen)
                                                } else {
                                                    notification.open({
                                                        //message: 'Notification Title',
                                                        description:
                                                        'You must be logged in to this account to perform operations',
                                                        onClick: () => {
                                                        console.log('Notification Clicked!');
                                                        },
                                                    });
                                                }
                                            }}>{ walletData.hbdSavings } HBD <Icon.ChevronDown /></DropDownHeader>
                                            {//hiveSelectOpen && (
                                                <DropDownListContainer style={{display: savingSelectOpen === true ? 'block' : 'none'}}>
                                                    <DropDownList>
                                                        <ListItem onClick={openHbdTrfFromSavingsModal}>Withdraw</ListItem>
                                                    </DropDownList>
                                                </DropDownListContainer>
                                            //)
                                            } 
                                        </DropDownContainer>

                                        <div style={customStyles}>
                                            <Modal
                                                isOpen={hbdTrfFromSavingsModalIsOpen}
                                                onRequestClose={closeHbdTrfFromSavingsModal}
                                                style={customStyles}
                                                contentLabel="Example Modal"
                                            >
                                                <div>
                                                    <span className="text-end"> <i style={{cursor: 'pointer', color: '#1b1c1d', backgroundColor: 'white'}} onClick={closeHbdTrfFromSavingsModal}><Icon.XCircle /></i></span>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <h6><b>Withdraw HBD From Savings</b></h6>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hbd-withdrawer">From</label>
                                                        <input type="text" id="hbd-withdrawer" name="hbd-withdrawer-input" style={{width: '100%', padding: '1%'}} value={profile} disabled />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <label htmlFor="hbd-withdraw-amount">Amount</label>
                                                        <input type="number" id="hbd-withdraw-amount" name="hbd-withdraw-amount-input" style={{width: '100%', padding: '1%'}} />
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '2%', display: wifInputDisplay}}>
                                                        <label htmlFor="hive-whjbs">Active Key</label>
                                                        <input type="password" id="hive-whjbs" name="hive-whjbs-input" style={{width: '100%', padding: '1%'}} />
                                                        <small>Please note that we do not store your active  key at any point during or after this transaction</small>
                                                    </div>
                                                    <div className='replyBox' style={{width: '100% !important', margin: '1% 2%'}}>
                                                        <a className="btn btn-sm text-end" style={{backgroundColor: '#1b1c1d', color: 'white', cursor: 'pointer !important'}}  onClick={() => {
                                                            sendHbdFromSavings()
                                                        }}>Withdraw</a>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="card-body">
                                <div className="row container">
                                    <div className="col-lg-8 col-md-8 col-sm-12 text-left">
                                        <h5 className="text-left">Estimated Account Value</h5>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-right">
                                        <h6 className="text-right">${ walletData.estimatedAccountValue }</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Wallet;