import {
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import Home from "./components/home"
import Login from "./components/login"
import Post from "./components/post"
import New from "./components/new"
import Edit from "./components/edit";
import Profile from "./components/profile"
import UserPosts from "./components/userPosts";
import Wallet from "./components/wallet";
import UserReplies from "./components/userReplies";
import Communities from "./components/communities";
import Category from "./components/category";
import * as Icon from 'react-bootstrap-icons';
import NavbarCon from "./components/widgets/navbar";
import Nav from 'react-bootstrap/Nav';
import AboutUs from "./components/aboutUs";
import FAQ from "./components/widgets/faq";
import TermsOfService from "./components/widgets/tos";
import AppFooter from "./components/widgets/footer";

const restrictedPaths = ['/new', '/edit']
let token = localStorage.getItem('token')
const username = localStorage.getItem('username')

function imageExists(image_url){

  var http = new XMLHttpRequest();

  http.open('HEAD', image_url, false);
  http.send();

  return http.status !== 404;

}


export const NavLinks = function() {
  if (token !== null && username !== null) {
    return (<>
              <Nav>
                <li className="nav-item">
                  <a className="nav-link text-white" aria-current="page" href={"/p?user=" + username}>Profile < Icon.PersonFill /></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" aria-current="page" href={"/new?user=" + username}>New Post < Icon.PencilFill /></a>
                </li>
                {/*<li className="nav-item">
                  <a className="nav-link text-white" aria-current="page" href={"/communities"}>Communities < Icon.PeopleFill /></a>
                </li>*/}
                <li className="nav-item">
                  <a className="nav-link text-white" aria-current="page" href={"/wallet?user=" + username}>Wallet < Icon.WalletFill /></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" aria-current="page" style={{cursor: 'pointer'}} onClick={logout}>Logout <Icon.DoorOpenFill /></a>
                </li>
              </Nav>
              <Nav className="me-auto">
                <li className="nav-item">
                  <a className="nav-link text-white text-center" aria-current="page" href={"/p?user=" + username}>
                    <small className="text-center" style={{color: 'white'}}>@{username} </small> 
                    <img id="avatar" alt={'user avatar'} src={`https://images.hive.blog/u/${username}/avatar`} style={{borderRadius: '50%', border: '1px solid white'}} loading="lazy" />
                  </a>
                </li>
              </Nav>
            </>) 
  }

  
  if (token === null) {
    if(restrictedPaths.includes(window.location.pathname)) {
      window.location.replace('/')
    }
    return (<>         
              <li className="nav-item" id="login-link">
                <a className="nav-link text-white" aria-current="page" href="/login">Login</a>
              </li>
            </>)
    }
}

function logout() {
  localStorage.removeItem('token')
  localStorage.removeItem('username')
  localStorage.removeItem('id')
  localStorage.removeItem('ajbhs')
  localStorage.removeItem('hasKey')
  localStorage.removeItem('hasExpire')
  window.location.reload()
};

function App() {
  
  if (window.location.protocol === 'http:' && !window.location.href.includes('localhost:')) {
    let currentUrl = window.location.href
    window.location.replace('https://' + currentUrl.split('://')[1])
  }

  return (
      <div className="App">

        <div>
          <NavbarCon />
        </div>
        
        <div className="page-view">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/post" element={<Post />} />
              <Route path="/new" element={<New />} />
              <Route path="/edit" element={<Edit />} />
              <Route path="/u" element={<Profile />} />
              <Route path="/p" element={<UserPosts />} />
              <Route path="/replies" element={<UserReplies />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/communities" element={<Communities />} />
              <Route path="/category" element={<Category />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/tos" element={<TermsOfService />} />
            </Routes>
        </div>

        <AppFooter />
      </div>
  );
}

export default App;
