import React from 'react';
import { Row, Col, Typography, Card } from 'antd';
import TeamMembers from './widgets/teamMembers';
import '../aboutUs.css'

const { Title, Paragraph } = Typography;

const AboutUs = () => {
  return (
    <div className="about-us">
      <Row gutter={16}>
        <Col xs={24} md={24}>
          <Card title="Our Mission" className="mission-card">
            <Title level={3}>Empowering STEM Enthusiasts</Title>
            <Paragraph style={{textAlign: 'left'}}>
                STEMsocial is a curation project dedicated to improving the quality of STEM (science, technology, engineering & mathematics) content on the Hive blockchain. However, as things continue to unfold, we expanded our goals of promotion to new heights.
            </Paragraph>
            <Paragraph style={{textAlign: 'left'}}>
                The problem, as we see it, has always been that those with the greatest knowledge of STEM subjects (i.e. researchers actively involved in the latest research or passionate hobbyists) lack incentive to communicate their work to mainstream audiences, leaving the majority of scientific reporting to journalists. As a result, only the most positive, groundbreaking and bizarre stories get heard, often becoming misinterpreted into ‘fake news’ by mainstream media.
            </Paragraph>
          </Card>
        </Col>
        <Col xs={24} md={24}>
          <Card title="How We Work" className="vision-card">
            <Title level={3}>A Thriving STEM Community</Title>
            <Paragraph style={{textAlign: 'left'}}>
                We at STEMsocial offer a new approach. We provide a space among a thriving global community of like-minded individuals where both experts and passionate STEM bloggers can freely distribute their knowledge in a social media environment that actively promotes and rewards honest, reliable and high-quality content. Inspired by the scientific method itself, STEMsocial offers a three-layered system of review for each publication we promote:
            </Paragraph>
            <ul style={{textAlign: 'left'}}>
                <li>Curators first read, review and determine the level of STEMsocial support for the publication. This is always verified by a second curator.</li>
                <li>A team of honor members scrutinizes the actions of curators, having rewards removed among other necessary actions when the decisions are in some way flawed.</li>
                <li>All supported content is open to the public, who can further scrutinize and push for any necessary actions against misleading or fraudulent content that managed to pass through. This process means that STEMsocial is one of the greatest spaces on the blockchain to find trustworthy, uncensored information that is both digestible and enjoyable to be a part of.</li>
            </ul>
            <Paragraph style={{textAlign: 'left'}}>
                STEMsocial offers content promotion in 6 languages: Chinese, English, French, German, Italian and Spanish.
            </Paragraph>
          </Card>
        </Col>
      </Row>
      <TeamMembers />
    </div>
  );
};

export default AboutUs;
