import {React, useCallback, useState, useMemo, useEffect} from "react";
import {keychain, isKeychainInstalled, hasKeychainBeenUsed} from '@hiveio/keychain'
import ops from "../services/hiveOps"
import SimpleMdeReact from "react-simplemde-editor";
import { InputTags } from 'react-bootstrap-tagsinput'
import * as Icon from 'react-bootstrap-icons';
import 'react-bootstrap-tagsinput/dist/index.css'
import randomstring from 'randomstring';
import apiOps from "../services/apiOps";
import SelectSearch from 'react-select-search';
import { ToastContainer } from "react-toastr";
import axios from "axios";
import { notification, Switch, Row, Col, Form, Button, Input } from 'antd';

// components


const hive = require("@hiveio/hive-js")
function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

function calculatePercentage(part, whole) {
    if (typeof part !== 'number' || typeof whole !== 'number' || whole === 0) {
        // Ensure that the input values are valid numbers and the denominator is not zero
        console.error('Invalid input. Please provide valid numeric values, and ensure the denominator is not zero.');
        return null;
    }

    const percentage = (part / whole) * 100;
    return percentage;
}

function calculateActualValue(percentage, whole) {
    if (typeof percentage !== 'number' || typeof whole !== 'number' || percentage < 0 || percentage > 100) {
        // Ensure that the input values are valid percentages and the percentage is between 0 and 100
        console.error('Invalid input. Please provide a valid percentage (between 0 and 100) and a valid numeric value.');
        return null;
    }

    const actualValue = (percentage / 100) * whole;
    return actualValue;
}

//let popularCommunities = [];
//let inscrybmde = new InscrybMDE()


const author = getUrlParameter('user')
let permlink = getUrlParameter('permlink')
let imagesUplArr = []

function New() {
    let [value, setValue] = useState("");
    let [tags, setTags] =  useState(['stemsocial']);
    let [bene, setBene] =  useState([]);
    let [inputBene, setInputBene] = useState(false);
    let [communities, setCommunities] = useState([]);
    let [chosenCommunity, setChosenCommunity]= useState('hive-196387');
    //const [initBeneUsername, ]
    let [currentImage, setCurrentImage] = useState('');
    let [startCur, setStartCur] = useState(0);
    let [endCur, setEndCur] = useState(0);
    const [beneForm] = Form.useForm();

    // Initialize the form with initial values
    beneForm.setFieldsValue({ username: 'stemsocial', weight: '5' });
    let delay = '1000'
    // draft state
    let [currentBody, setCurrentBody] = useState('')
    let [currentTitle, setCurrentTitle] = useState('')
    let [currentParentPerm, setCurrentParentPerm] = useState(chosenCommunity)
    let [currentTags, setCurrentTags] = useState('')
    let [lineAndCursor, setLineAndCursor] = useState(null);

    let getLineAndCursorCallback = useCallback((position) => {
        setLineAndCursor(position);
    }, []);

    function finishPosting(author, permlink) {
        window.location.replace(`/p?user=${author}`)
    }

    useEffect(() => {
        async function getCommunities () {
            let popularCommunities = await ops.getPopularCommunities()
            setCommunities(popularCommunities)
            console.log(popularCommunities)
        }

        getCommunities()

        async function setContents() {
            if (permlink !== true) {
                document.getElementById('postTitle').value = localStorage.getItem(`currentTitle`) && localStorage.getItem(`currentTitle`).length > 0 ? localStorage.getItem(`currentTitle`) : '';
                const bodyVal = localStorage.getItem(`currentBody`) && localStorage.getItem(`currentBody`).length > 0 ? localStorage.getItem(`currentBody`) : '';
                setValue(bodyVal)
            }
        }

        setContents()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            localStorage.setItem('currentParentPerm', document.getElementById('communitiesSelect').value)
            localStorage.setItem('currentAuthor', author)
            localStorage.setItem('currentTitle',  document.getElementById('postTitle').value)
            localStorage.setItem('currentBody',  value)
            console.log('saved')
        }, 3000);
        return () => clearInterval(interval);
    })

    const onSwitchChange = (checked) => {
        setInputBene(checked)
    };
   
    async function handlePostSubmit() {
        notification.open({
            //message: 'Notification Title',
            description:
            'Submitting',
            onClick: () => {
            console.log('Notification Clicked!');
            },
        });
        const title = document.getElementById('postTitle').value
        let body = value;
        const parentAuthor = '';
        let permlink = randomstring.generate({
            length: 8,
            charset: 'alphabetic'
        }).toLowerCase();
        const parentPermlink = chosenCommunity.length > 0 ? chosenCommunity : tags[0]
        const jsonMetadata = {tags, app: 'stemsocial/v1', image: imagesUplArr }

        const comment = {
            parent_author: parentAuthor,
            parent_permlink: parentPermlink,
            author,
            permlink,
            title,
            body,
            json_metadata: JSON.stringify(jsonMetadata)
        };

        let benefactor_global = [
            [0, {beneficiaries: bene}]
        ];


        const comment_options = ['comment_options', {
            author,
            permlink,
            max_accepted_payout: '100000.000 SBD',
            percent_hbd: 10000,
            allow_votes: true,
            allow_curation_rewards: true,
            extensions: benefactor_global
        }]

        const operation = ['comment', comment]

        const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestTransfer', 'test', author, 5,  'test memo', 'HIVE')
        
        const keychainStatus = localStorage.getItem('keychain')

        console.log(comment, comment_options)

        if (isKeychainInstalled && keychainStatus === 'yes') {
            // do your thing

            try {
                const {success, msg, cancel, notInstalled, notActive} = await keychain(window, 'requestBroadcast', author, [operation, comment_options], 'Posting');
                localStorage.removeItem('currentParentPerm')
                localStorage.removeItem('currentAuthor')
                localStorage.removeItem('currentTitle')
                localStorage.removeItem('currentBody')
                if (success) {
                    finishPosting(author, permlink)
                }
                
            } catch (error) {
                console.log(error)
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Error encountered',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }
        }
        // User didn't cancel, so something must have happened
        else if(!cancel) {
            if(notActive) {
                notification.open({
                    //message: 'Notification Title',
                    description:
                    'Please allow Keychain to access this website',
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            } else {
                try {
                    const dybnjg = localStorage.getItem('ajbhs')
                    //const sendPost = await hive.broadcast.commentAsync(dybnjg, parentAuthor, parentPermlink, author, permlink, title, body, JSON.stringify(jsonMetadata))
                    await hive.broadcast.sendAsync({
                        operations: [operation, comment_options]
                      }, {posting: dybnjg});
                      localStorage.removeItem('currentParentPerm')
                      localStorage.removeItem('currentAuthor')
                      localStorage.removeItem('currentTitle')
                      localStorage.removeItem('currentBody')
                    finishPosting(author, permlink)
                } catch (error) {
                    console.log(error)
                    notification.open({
                        //message: 'Notification Title',
                        description:
                        'Error encountered',
                        onClick: () => {
                        console.log('Notification Clicked!');
                        },
                    });
                    
                }
            }
        }
    }

    async function changeSelect(v) {
        setChosenCommunity(v.target.value)
        setCurrentParentPerm(v.target.value);
    }

    async function onImageInputChange(event) {
        try {
            let body = new FormData()
            body.set('key', '49f6872819f2b77a024f3a4326f29a56')
            body.append('image', event.target.files[0])

            let imageUploadOp = await axios({
                method: 'post',
                url: 'https://api.imgbb.com/1/upload',
                data: body
            })

            let url = imageUploadOp.data.data.display_url
            let text_to_insert = `![](${url})`
            
            let line = lineAndCursor.line
            
            let valueArr = value.split('\n')

            valueArr.splice(line, 0, text_to_insert)
        
            setValue(valueArr.join('\n'))
            imagesUplArr.push(url)
            console.log(imagesUplArr)
        } catch (error) {
            console.error(error)
        }
        
    }

    const onBeneFinish = (values) => {
        console.log('Finish:', values);
        setBene([...bene, {account: values.username, weight: calculateActualValue(parseFloat(values.weight), 10000)}]);
        // After successful submission, reset the form fields
        beneForm.resetFields();
    };

    const removeBene = (idToDelete) => {
        const updatedArray = bene.filter((obj) => obj.account !== idToDelete);
        setBene(updatedArray);
    };

    const autofocusNoSpellcheckerOptions = useMemo(() => {
        return  {
          hideIcons: ['preview', 'side-by-side'],
          previewImagesInEditor: true,
          spellChecker: false
        };
    }, []);


    return (
        <div className="post-detail" id="post-page-content" style={{paddingTop: '15vh'}}>
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 col-sm-12 container" key={author} data-author={author}>
                    <div className="container mb-3 text-cont">
                        <label htmlFor="postTitle" className="form-label text-left">Title</label>
                        <input type="text" className="form-control" id="postTitle" placeholder="Enter a Title for Your Post Here" />
                    </div>

                    {communities.length > 0 && <div className="container mb-3 text-cont"> 
                       <label htmlFor="communitiesSelect">Community</label>
                        <select value={chosenCommunity} onChange={changeSelect} style={{
                            width: '100%',
                            height: '50px'
                        }} id="communitiesSelect" disabled>
                            <option value={'hive-196387'}>StemSocial</option>
                        </select>
                    </div>}

                    <div className="container mb-3 text-start">
                        <input accept="image/gif,image/jpeg,image/jpg,image/png" type="file" id="upInput" onChange={onImageInputChange} placeholder='Click to choose file' /><br />
                        <small>Click to upload image</small><br />
                    </div>


                    <div className="container mb-3">
                        <label htmlFor="postEditor" className="form-label">Description</label>
                        <SimpleMdeReact value={value} onChange={(value) => {
                            setValue(value)
                            setCurrentBody(value)
                        }} options={autofocusNoSpellcheckerOptions} getLineAndCursor={getLineAndCursorCallback} id="post-body" style={{textAlign: 'left'}} />
                    </div>

                    <div className="mb-3">
                        <span>Add Beneficiaries</span> <Switch onChange={onSwitchChange} />

                        {inputBene && 
                        <div style={{ margin: 10 }}>
                            <Row>
                                <Col span={24} style={{ margin: '3vh auto'}}>
                                    <Form form={beneForm} name="bene_add" onFinish={onBeneFinish} style={{ width: '100%', justifyContent: 'center' }}>
                                        <Form.Item
                                            name="username"
                                            rules={[{ required: true, message: 'Please input beneficiary username!' }]}
                                        >
                                            <Input placeholder="Beneficiary Username" />
                                        </Form.Item>
                                        <Form.Item
                                            name="weight"
                                            rules={[{ required: true, message: 'Please input beneficiary weight in percent' }]}
                                        >
                                            <Input
                                            type="number"
                                            placeholder="Percentage(%)"
                                            max={100}
                                            suffix={'%'}
                                            />
                                        </Form.Item>
                                        <Form.Item shouldUpdate>
                                            {() => (
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Add
                                            </Button>
                                            )}
                                        </Form.Item>
                                    </Form>
                                </Col>
                                {bene.map(b => (
                                    <Col span={24} style={{ margin: '2vh auto'}}>
                                        <span style={{width: '50vw', border: '1px solid #000', padding: '1.5vh 1.5vw'}}>{b.account}</span> <span style={{width: '50vw', border: '1px solid #000', padding: '1.5vh 1.5vw'}}>{calculatePercentage(b.weight, 10000)}%</span> <span onClick={() => {removeBene(b.account)}} style={{width: '50vw', border: '1px solid #000', padding: '1.5vh 1.5vw', cursor: 'pointer'}}><Icon.XCircle /></span>
                                    </Col>
                                ))}
                            </Row>
                        </div>}
                    </div>
                    

                    <div style={{ margin: 10 }}>
                        <div className='input-group'>
                            <InputTags values={tags} onTags={(value) => {
                                console.log(value)
                                setTags(value.values)
                                setCurrentTags(value.values)
                            }} />
                            <button
                            className='btn btn-outline-secondary'
                            type='button'
                            data-testid='button-clearAll'
                            onClick={() => {
                                setTags([])
                                setCurrentTags([])
                            }}
                            >
                            Delete all
                            </button>
                        </div>
                    </div>

                    <div className="container mb-3 text-right">
                        <a className="btn" id="submitPost" style={{backgroundColor: "#1b1c1d", color: 'white'}} onClick={() => {
                            handlePostSubmit()
                        }}>Submit</a>
                    </div>
                </div>
                <div className="col-lg-2"></div>
                <hr />
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    );
}

export default New;